import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { green } from "@mui/material/colors";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "grey",
  },
}));


//---------------------------------------------------------------------------

const CustomMaterialSwitch =({checked,label,set}:{
    checked:boolean;
    label:string;
    set:any;
})=> {
  return (
    <FormGroup sx={{
       // marginLeft:1.4,
    }}>
      <FormControlLabel control={<PinkSwitch checked={checked} />} label={label} onChange={set} color='warning'/>
    </FormGroup>
  );
}

export default CustomMaterialSwitch;