import styled from "styled-components";

//---------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  gap: 10px;
  //wrap
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

//------------------------------------------------------------------------

const DeveloperPageContent = ({ theme }) => {
  return (
    <Container>
      <ContentContainer>
        <div
          id="test_external_loader"
          style={{
            width: "100%",
            height: "100%",
            background: "white",
          }}
        >
          {/* <iframe
            title="customIframe"
            allowfullscreen=""
            src="/load-html"
            frameborder="0"
            id="customIframe"
            name="customIframe"
            class="sc-beqWaB loyaau intrinsic-ignore bokun-iframe"
            style={{
              width: "100%",
              height: "100%",
            }}
            onLo
          ></iframe> */}
        </div>
      </ContentContainer>
    </Container>
  );
};

export default DeveloperPageContent;
