import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

//---------------------------------------------------------------------------

const CustomMaterialSelect = ({
  values,
  selected,
  label,
  set,
  error,
}: {
  values: any;
  selected: any;
  label: string;
  set: any;
  error: boolean;
}) => {
  // console.log("values", values);
  // console.log("selected", selected);

  return (
    <Autocomplete
      //size="medium"
      sx={{
        flex: 1,
        minWidth: 200,
      }}
      autoComplete
      isOptionEqualToValue={(option, value: any) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      options={values.map((item: any) => JSON.stringify(item))}
      renderInput={(params) => (
        <TextField
          margin="dense"
          multiline
          {...params}
          label={label}
          error={error}
          color="success"
        />
      )}
      onChange={(e, value) => {
        set(JSON.parse(value));
      }}
      getOptionLabel={(option) => JSON.parse(option)?.label || "None"}
      value={JSON.stringify(selected)}
    />
  );
};

export default CustomMaterialSelect;
