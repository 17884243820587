import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { useAxios } from "../../axiosProvider";
import {
  useGetUserOfficeDutyStatus,
  useGetAllOfficeDutyUsers,
  useUpdateUserOfficeDutyStatus,
} from "../../reactQueryHooks";
import NavButtonWithCustomMenu from "./NavButtonWithCustomMenu";
import MenuItem from "@mui/material/MenuItem";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { RotatingLines } from "react-loader-spinner";
import { Tooltip } from "@mui/material";
//import toast from "react-hot-toast";
import io from "socket.io-client";
//const socketTestBookingsServer = io("http://localhost:3000");
const socketBookings = io("https://getaways-bookings.herokuapp.com", {
  transports: ["websocket"],
});

// <RotatingLines
//   width="13"
//   strokeColor="grey"
//   //speed
//   strokeWidth={4}
// />;

//-----------------------------------------------------------------------

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 5000;
`;

const ProfileIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  /* border: ${({ onOfficeDuty }) =>
    onOfficeDuty ? "2px solid #3b943b" : "2px solid #B95151"}; */
  background-color: #e6e6e6;
  width: 28px;
  height: 28px;
  font-size: 12px;
  @media (max-width: 400px) {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
  //padding: 8px;
  border-radius: 100%;
  i {
    color: #4a8ed1;
  }
`;

// Keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

// Styled component for the blinking dot
const BlinkingDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: rgb(111 207 111);
  border-radius: 50%;
  animation: ${blink} 1s linear infinite;
`;

//-----------------------------------------------------------------------

const ProfileIcon = () => {
  const { user, setIsAuthenticated } = useAxios();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    data: onOfficeDuty,
    isFetching: isFetchingOfficeDutyStatus,
    refetch: refetchOfficeDutyStatus,
  } = useGetUserOfficeDutyStatus(user?._id);

  const { data: officeDutyUsers, refetch: refetchOfficeDutyUsers } =
    useGetAllOfficeDutyUsers();

  const { mutateAsync: updateUserOfficeDutyStatus } =
    useUpdateUserOfficeDutyStatus();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldShowFullName = windowWidth >= 550;

  const name = user.name;
  const splitName = name.split(" ");

  useEffect(() => {
    // subscribe socket listener on mount
    socketBookings.on(`getaways_suite/refresh_users_on_duty`, async (data) => {
      await refetchOfficeDutyStatus()
      await refetchOfficeDutyUsers()
      //toast.success("Someone is on duty now! 🎉");
    })

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketBookings.off(`getaways_suite/refresh_users_on_duty`)
    }
  }, [refetchOfficeDutyUsers, refetchOfficeDutyStatus])

  //-----------------------------------------------------------------------

  return (
    <ProfileIconWrapper>
      <ProfileIconContainer>
        <Icon>
          <i className={`fas fa-user${user ? "" : "-slash"}`}></i>
        </Icon>

        <NavButtonWithCustomMenu
          title={
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>
                {shouldShowFullName
                  ? user.name
                  : splitName.map((name, index) => `${name[0].toUpperCase()}`)}
              </span>
              <i className={`fa-solid fa-angle-down`}></i>
            </span>
          }
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              //gap: "5px",
              //backgroundColor: "rgb(250 250 250)",
              backgroundColor: "white",
            }}
          >
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                fontSize: "13px",
              }}
              onClick={async () => {
                try {
                  await updateUserOfficeDutyStatus({
                    id: user._id,
                    isOnDuty: !onOfficeDuty.data,
                  })
                  refetchOfficeDutyStatus()
                } catch (error) {
                  console.log(error)
                  alert(error?.response?.data || error.toString())
                }
              }}
            >
              <span>{onOfficeDuty?.data ? `Set OFF duty` : `Set ON Duty`}</span>
              <i
                style={{
                  fontSize: "11px",
                  color: "#747474",
                }}
                className="fa-solid fa-user-clock"
              ></i>
            </MenuItem>
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                fontSize: "13px",
              }}
              onClick={async () => {
                try {
                  await updateUserOfficeDutyStatus({
                    id: user._id,
                    isOnDuty: false,
                  })
                  setIsAuthenticated(false)
                } catch (error) {
                  console.log(error)
                  alert(error?.response?.data || error.toString())
                }
              }}
            >
              <span>Log-out</span>
              <i
                style={{
                  fontSize: "12px",
                  color: "#747474",
                }}
                className="fa-solid fa-right-from-bracket"
              ></i>
            </MenuItem>
          </div>
        </NavButtonWithCustomMenu>

        {isFetchingOfficeDutyStatus ? (
          <RotatingLines
            width="14"
            strokeColor="rgb(59 59 59)"
            strokeWidth={4}
          />
        ) : (
          <Tooltip
            //arrow
            leaveDelay={200}
            onOpen={refetchOfficeDutyUsers}
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  padding: "4px 5px",
                }}
              >
                <span
                  style={{
                    //backgroundColor: "white",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {onOfficeDuty?.data
                    ? "You are on Duty ✅"
                    : "You are off Duty ⛔"}
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    //backgroundColor: "white",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "5px",
                    padding: "5px 7px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    USERS ON DUTY
                  </span>
                  {officeDutyUsers?.data?.length > 0 &&
                    officeDutyUsers.data.map((user) => (
                      <span
                        key={user._id}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <BlinkingDot /> {user.name}
                      </span>
                    ))}
                  {!officeDutyUsers?.data?.length && (
                    <span style={{ color: "rgb(235 154 154)" }}>
                      No users on duty
                    </span>
                  )}
                </span>
              </div>
            }
            placement="bottom"
          >
            <SupportAgentIcon
              sx={{
                color: onOfficeDuty?.data ? "rgb(59 148 59)" : "rgb(194 83 83)",
                fontSize: "20px",
              }}
              //fontSize="small"
            />
          </Tooltip>
        )}
      </ProfileIconContainer>
    </ProfileIconWrapper>
  )
};

export default ProfileIcon;
