import { useState, useRef } from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import OutsideClickHandler from "react-outside-click-handler";
import { useSearchBookings } from "../../../reactQueryHooks";
import Booking from "./Booking";
import noResultsImage from "./empty-box.png";

//---------------------------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 150px;
  max-height: 80vh;
  max-width: 90vw;
  min-width: 400px;
  position: absolute;
  left: 50%;
  top: ${({ top }) => top}px;
  transform: translateX(-50%);
  overflow-y: hidden;
  padding: 7px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.3);
  @media (max-width: 550px) {
    display: none;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-right: 3px;
  span {
    font-size: 12px;
  }
  .no-search-term {
    color: #999;
  }
  .no-results {
    color: #999;
  }
  .loading-search {
    color: dodgerblue;
  }
  .error-search {
    color: indianred;
  }
`;

const Input = styled.input`
  background-color: #f0f0f0;
  font-size: 12px;
  padding: 5px 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 550px) {
    display: none;
  }
`;

//---------------------------------------------------------------------------

const Search = () => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null); // Use useRef instead of createRef
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400); // Debounce search term
  const emptySearchTerm = debouncedSearchTerm.length === 0;

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const { data, isLoading, isError, error } = useSearchBookings({
    searchTerm: debouncedSearchTerm,
    enabled: debouncedSearchTerm.length > 0,
  });
  const emptyResults = data?.data?.length === 0;

  // Check if inputRef.current exists before accessing its properties
  const inputOffsetTop = inputRef.current ? inputRef.current.offsetTop : 0;

  const handleOutsideClick = (event) => {
    if (!inputRef.current.contains(event.target)) {
      setIsInputFocused(false);
    }
  };

  return (
    <div>
      <Input
        type="text"
        placeholder="🔍 Search..."
        ref={inputRef}
        onFocus={handleInputFocus}
        onClick={() => {
          setIsInputFocused(true);
        }}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
      />
      {isInputFocused && (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <Wrapper top={inputOffsetTop + inputRef.current.offsetHeight + 5}>
            <ResultsContainer>
              {!isLoading && emptySearchTerm && (
                <span className="no-search-term">Make a search..</span>
              )}
              {isLoading && <span className="loading-search">Loading...</span>}
              {!isLoading && isError && !emptySearchTerm && (
                <span className="error-search">{error?.response?.data}</span>
              )}
              {!isLoading && emptyResults && (
                <span className="no-results">No results found</span>
              )}
              {!isLoading &&
                !emptyResults &&
                data?.data.map((booking) => (
                  <Booking key={booking._id} booking={booking} />
                ))}
              {!isLoading && (emptyResults || emptySearchTerm) && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                    }}
                    src={noResultsImage}
                    alt="No results found"
                  />
                </div>
              )}
            </ResultsContainer>
          </Wrapper>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Search;
