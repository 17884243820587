import styled from "styled-components";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useFetchEntities } from "../../../reactQueryHooks";
import { useAxios } from "../../../axiosProvider";

//------------------------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: gainsboro;
  padding: 10px;
  gap: 10px;
  width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
`

//------------------------------------------------------------------------------------------

const PromoMessageForm = ({ booking }) => {
  const { user } = useAxios();
  const { data: entities } = useFetchEntities();
  const products = entities?.data?.products || [];
  const [promoCode, setPromoCode] = useState("PROMO-DIMITRA");
  const [promoSuggestion, setPromoSuggestion] = useState("Select a suggestion");
  const [promoMessage, setPromoMessage] = useState("");
  const name = user.name.split(" ")[0];

const product = entities.data.products.find(
  (product) => product._id === booking.product_id
);

useEffect(() => {
  const promo_message = `Hello! This is ${name} from Getaways Greece and we are excited for you to join our ${
    product.platform_product_name
  }. 
    
Enhance your trip with our ${
    promoSuggestion === "Select a suggestion" ? "[SUGGESTION]" : promoSuggestion
  } or you can choose any other tour with a 15% discount by using the following code upon checkout: ${promoCode} 

Discover all the tours we offer at https://getawaysgreece.com/shop/

The Getaways Greece Team

[${booking.ref}]
`;
  setPromoMessage(promo_message);
}, [
  promoCode,
  promoSuggestion,
  product.platform_product_name,
  name,
  booking.ref,
]);

  return (
    <Container className="exclude-from-submit-to-enter">
      <TextField
        label={"Promo code"}
        style={{
          backgroundColor: "white",
        }}
        type="text"
        InputProps={{
          style: {
            backgroundColor: "white",
          },
        }}
        error={promoCode === ""}
        fullWidth={true}
        value={promoCode}
        onChange={(e) => setPromoCode(e.target.value)}
        variant="filled"
      />
      <Select
        value={promoSuggestion}
        onChange={(e) => setPromoSuggestion(e.target.value)}
        style={{
          backgroundColor: "white",
          width: "100%",
        }}
        error={promoSuggestion === "Select a suggestion"}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        <MenuItem
          style={{
            fontSize: "14px",
          }}
          value={"Select a suggestion"}
        >
          {"Select a suggestion"}
        </MenuItem>

        {products.map((product) => (
          <MenuItem
            style={{
              fontSize: "14px",
            }}
            key={product._id}
            value={product.platform_product_name}
          >
            {product.platform_product_name}
          </MenuItem>
        ))}
      </Select>

      <TextField
        variant="filled"
        fullWidth={true}
        multiline={true}
        rows={15}
        //rowsMax={10}
        //error --> there is no suggestion,promo code or promo message
        error={
          promoCode === "" ||
          promoSuggestion === "Select a suggestion" ||
          promoMessage === ""
        }
        value={promoMessage}
        onChange={(e) => setPromoMessage(e.target.value)}
        label="Promo message"
        InputProps={{
          style: {
            backgroundColor: "white",
            fontSize: "14px",
          },
          className: "exclude-from-submit-to-enter",
          classes: {
            input: "exclude-from-submit-to-enter",
          },
        }}
      />
      <Button
        style={{
          backgroundColor: "#289E54",
          color: "white",
          width: "100%",
        }}
        variant="contained"
        onClick={async () => {
          if (!booking.client_phone) return alert("No phone number in booking");

          if (
            promoCode === "" ||
            promoSuggestion === "Select a suggestion" ||
            promoMessage === ""
          ) {
            alert("Please fill in all fields");
            return;
          }

          if (window.confirm("Open WhatsApp?")) {
            //deep link to whatsapp
            const num = booking.client_phone.replace(/[^0-9+]/g, "");
            const encodedEmailText = promoMessage
              .split("\n")
              .map((line) => encodeURIComponent(line))
              .join("%0A");
            const urlScheme = `whatsapp://send?text=${encodedEmailText}&phone=+${num}&abid=+${num}`;
            window.open(urlScheme, "_blank");
          }
        }}
      >
        WhatsApp
      </Button>
    </Container>
  );
};

export default PromoMessageForm;
