import { useState,useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useFetchEntities } from "../../../../reactQueryHooks";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  gap: 5px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  //padding: 0 5px 0 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  //background-color: white;
  gap: 10px;
  opacity: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
.fa-times{
    font-size: 21px;
}
  i {
    font-size: 17px;
    cursor: pointer;
    color: rgb(170 170 170);
  }
  &:hover {
    i {
      color: ${(props) => {
        if (props.title === "Add a note") {
            return "darkgreen";
        }
        if (props.title === "Edit note") {
            return "dodgerblue";
        }
        if (props.title === "Delete note") {
            return "indianred";
        }
        if (props.title === "Save note") {
            return "darkgreen";
        }
        if (props.title === "Cancel") {
            return "indianred";
        }
    }}
  }
}
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 2px;
  padding: 5px;
  background-color: white;
  border-radius: 4px;
  //box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  //border: thin solid rgb(235 235 235);
  border-left: 4px solid transparent;
  cursor: pointer;

  &:hover {
    //box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    //border-bottom: thin solid rgb(235 235 235);
    //border-left: 2px solid dodgerblue;
    border-left: 4px solid rgb(223 152 29);

    ${ButtonsContainer} {
      opacity: 1;
    }
  }
`;

const NoteBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const NoteBody = styled.div`
  font-style: italic;
  flex: 1;
  // font-weight: 600;
`;

const NoteInput = styled.input`
  flex: 1;
  background-color: rgb(241 241 241);
  //border-radius: 4px;
  //wrap with ellipsis
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Details = styled.div`
  font-size: 11px !important;
  color: dodgerblue;
`;

//----------------------------------------------------------------------

//moment(note.date).format("DD/MM/YYYY HH:mm"

const Note = ({
  note,
  onChange,
  onDelete,
  isEditing,
}: {
  note: { author: string; body: string; date: string };
  onChange: any;
  onDelete: any;
  isEditing?: boolean;
}) => {
  const [isEditable, setIsEditable] = useState(isEditing);
  const [noteBody, setNoteBody] = useState(note.body);
  const { data: entities }: any = useFetchEntities();
  const { user } = useAxios();
  const noteUser = entities?.data.staff.find((u: any) => u._id === note.author);
  const isOwnNote = noteUser?._id === user._id;

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter" && isEditable) {
        setIsEditable(false);
        onChange({
          body: noteBody,
          author: user._id,
          date: new Date().toISOString(),
        });
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [note, onChange, user._id, noteBody,isEditable]);

  //----------------------------------------------
  return (
    <NoteContainer>
      <NoteBodyContainer>
        {isEditable ? (
          <NoteInput
            autoFocus
            value={noteBody}
            onChange={(e) => setNoteBody(e.target.value)}
            placeholder="Add a note ..."
          />
        ) : (
          <NoteBody>{note.body.length ? note.body : "Empty note.."}</NoteBody>
        )}
        <ButtonsContainer>
          {isEditable ? (
            <>
              <ButtonContainer
                title="Save note"
                onClick={() => {
                  setIsEditable(false);
                  onChange({
                    body: noteBody,
                    author: user._id,
                    date: new Date().toISOString(),
                  });
                }}
              >
                <i className="fas fa-save"></i>
              </ButtonContainer>
              <ButtonContainer
                title="Cancel"
                onClick={() => {
                  setIsEditable(false);
                  setNoteBody(note.body);
                  note.body.length === 0 && onDelete();
                }}
              >
                <i className="fas fa-times"></i>
              </ButtonContainer>
            </>
          ) : (
            <>
              {isOwnNote && (
                <>
                  <ButtonContainer
                    title="Edit note"
                    onClick={() => setIsEditable(true)}
                  >
                    <i className="fas fa-edit"></i>
                  </ButtonContainer>
                  <ButtonContainer
                    title="Delete note"
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </ButtonContainer>
                </>
              )}
            </>
          )}
        </ButtonsContainer>
      </NoteBodyContainer>
      <Details>
        {noteUser.name} - {moment(note.date).format("DD/MM/YYYY HH:mm")}
      </Details>
    </NoteContainer>
  );
};

const NotesListInSortableTable = ({
  tourGroup,
  onChange,
}: {
  tourGroup: any;
  onChange: any;
}) => {
  return (
    <Container>
      {tourGroup.notes_list.map((note: any, index: number) => {
        return (
          <Note
            key={note.date}
            note={{
              author: note.author,
              body: note.body,
              date: note.date,
            }}
            onChange={(newNote: any) => {
              const newList = [...tourGroup.notes_list];
              newList[index] = {
                ...newNote,
                isEditing: false,
              };
              onChange(newList);
            }}
            onDelete={() => {
              const newList = [...tourGroup.notes_list];
              newList.splice(index, 1);
              onChange(newList);
            }}
            isEditing={note.isEditing}
          />
        );
      })}
    </Container>
  );
};

export default NotesListInSortableTable;
