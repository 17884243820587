import styled from "styled-components";
import { motion } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import moment from "moment";
import SelectionBar from "../../../selection_bar/SelectionBar";
import BookingsBarContent from "../bar/BookingsBarContent";
import BarLoader from "react-spinners/BarLoader";
import { useGetBookings, useFetchEntities } from "../../../../reactQueryHooks";
import BookingsTable from "./BookingsTable";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import FilterDropdown from "./FilterDropdown";
import SaveFiltersDropdown from "./SaveFiltersDropdown";
import Tooltip from "@mui/material/Tooltip";
import { generateExcelFromBookingsManager } from "../../day_planner_tour_groups/content/file_utilities";
import { AppContext } from "../../../../App";

//-----------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;
  //background-color: whitesmoke;
  width: 100%;
  //height: 50px;
  padding: 5px 0;
  input {
    height: 100%;
    background-color: transparent;
    //flex: 1;
    text-align: center;
    width: 100%;
    color: #5b5f59;
    font-size: 13px;
    font-weight: 600;
    //border-radius: 5px 0 0 5px;
    border: none;
    padding: 0;

    ::placeholder {
      color: #a3a3a3;
    }
  }
  select {
    height: 100%;
    //flex: 1;
    background-color: transparent;
    text-align: center;
    color: #5b5f59;
    font-size: 11px;
    font-weight: 600;
    border: none;
    //no outline
    outline: none;
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

const TableContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: auto;
  padding: 0 5px 0 0;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const NoBookingsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  h6 {
    color: ${(props) => props.theme.colors.red};
    font-weight: 600;
    font-size: 13px;
  }
`;
const InfoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: ${(props) => props.theme.colors.blue};
  @media (min-width: 1332px) {
    opacity: 1;
  }

  opacity: 0.3;

  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 30px;
  border: none;
  cursor: pointer;

  i {
    font-size: 25px;
    color: white;
  }
  //shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const OptionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  /* @media (min-width: 1332px) {
    opacity: 1;
  } */
`;

const OptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.3;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 30px;
  border: none;
  cursor: pointer;

  i {
    font-size: 20px;
    color: white;
  }
  //shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.green};
  @media (min-width: 1332px) {
    opacity: 1;
  }

  opacity: 0.3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  i {
    font-size: 20px;
    color: white;
  }

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const StyledPopover = styled(Popover)`
  max-width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const FilterItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FilterItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //border-radius: 3px;
  background-color: #f8f8f8;
  gap: 5px;
  padding: 5px;
  width: 100%;
`;

const FilterItem = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 3px;
  background-color: white;
  /* background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue1 : "whitesmoke"};
    color : ${({ isSelected, theme }) => (isSelected ? "white" : "black")}; */
  cursor: pointer;
  i {
    font-size: 15px;
    color: ${(props) => props.theme.colors.blue};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: white;
    select {
      color: white;
    }
    i {
      color: white;
    }
  }
  font-size: 12px;
  i {
    font-size: 15px;
    color: ${(props) => props.theme.colors.blue};
  }
`;

const ResetAllButton = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  &:hover {
    color: ${(props) => props.theme.colors.red};
  }

  cursor: pointer;
`;

const LoadButton = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  &:hover {
    color: ${(props) => props.theme.colors.green};
  }

  cursor: pointer;
`;

const FetchMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  min-height: 35px;
  cursor: pointer;
  &:hover {
    span {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  span {
    font-size: 13px;
  }
`;

//------------------------------------------------------------------------

const BookingsContent = () => {
  const { data: entities } = useFetchEntities();
  const { setBookingFormBookingId, isBookingFormOpen, setIsBookingFormOpen } =
    useContext(AppContext);
  //-------------------------

  const [selectedProductIds, setSelectedProductIds] = useState(
    entities.data.products.map((product) => product._id)
  );
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    entities.data.channels.map((channel) => channel._id)
  );

  //---------------------------
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedSavedFilterLabel, setSelectedSavedFilterLabel] =
    useState(null);
  const [showOnlyValidBookings, setShowOnlyValidBookings] = useState(false);
  const [showByEventDate, setShowByEventDate] = useState(false);

  //useInfiniteQuery for pagination
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useGetBookings(
    moment(startDate).format("YYYY-MM-DD"),
    endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    "default", //sortQuery
    searchText ? searchText : "all", //searchQuery
    selectedProductIds,
    selectedChannelIds,
    showOnlyValidBookings,
    showByEventDate,
    true, //isEnabled
    () => {} //success callback
  );

  const handleAddFilterClick = () => {
    while (true) {
      const filterLabel = prompt("Enter a filter label:");

      if (filterLabel === null) {
        // User clicked cancel, break the loop
        return;
      } else if (filterLabel.trim() !== "") {
        // User provided a non-empty input, continue with the code
        let bookings_filters =
          JSON.parse(localStorage.getItem("bookings_filters")) || [];

        // Check if a filter with the same label already exists
        const filterExists = bookings_filters.some(
          (filter) => filter.label === filterLabel
        );

        if (filterExists) {
          // Show alert and return to the prompt
          alert("This filter name exists, choose a different name");
        } else {
          // Add the new filter to bookings_filters
          const filter = {
            label: filterLabel,
            value: {
              productIds: selectedProductIds,
              channelIds: selectedChannelIds,
              searchText: searchText,
              showOnlyValidBookings: showOnlyValidBookings,
              showByEventDate: showByEventDate,
            },
          };
          bookings_filters.push(filter);
          localStorage.setItem(
            "bookings_filters",
            JSON.stringify(bookings_filters)
          );
          break;
        }
      } else {
        // User provided an empty input, show the alert and continue the loop
        alert("Please enter a filter name");
      }
    }
  };

  const isLoading = isFetching && !isFetchingNextPage;

  useEffect(() => {
    refetch();
  }, [showByEventDate, startDate, endDate, refetch]);

  //-----------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <BookingsBarContent
          isModalOpen={isBookingFormOpen}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          refetch={refetch}
        />
      </SelectionBar>
      <ContentWrapper>
        <ContentContainer>
          <InputContainer
            style={{
              opacity: isLoading ? 0 : 1,
              transition: "opacity 0.2s",
            }}
          >
            <input
              placeholder="Search booking REF or Client name then press enter"
              autoFocus
              autoCorrect="off"
              spellCheck="false"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  refetch();
                }
              }}
            ></input>
          </InputContainer>

          <TableContainer>
            {!isLoading && data?.bookings.length > 0 && (
              <BookingsTable
                bookings={data.bookings}
                showByEventDate={showByEventDate}
              />
            )}

            {isLoading && !isFetchingNextPage && (
              <SpinnerContainer>
                <BarLoader color="#606060" loading={true} />
              </SpinnerContainer>
            )}

            {!isLoading && data?.bookings.length === 0 && (
              <NoBookingsContainer>
                <h6>No bookings returned</h6>
              </NoBookingsContainer>
            )}

            {hasNextPage && !isLoading && !isFetchingNextPage && (
              <FetchMoreContainer onClick={() => fetchNextPage()}>
                <span>
                  {`Load more ${data?.currentPage + 1} / ${data?.totalPages}`}
                </span>
              </FetchMoreContainer>
            )}
            {isFetchingNextPage && (
              <FetchMoreContainer>
                <BarLoader color="#606060" loading={true} />
              </FetchMoreContainer>
            )}
          </TableContainer>
        </ContentContainer>

        {!isBookingFormOpen && (
          <OverlayTrigger
            //container={containerRef.current} //contain within the parent element (refresh page)
            trigger="click"
            rootClose //close on click outside
            placement="right-start"
            overlay={
              <Popover>
                <PopoverContent
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    minWidth: "0",
                    padding: "2px 5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#1874ce",
                      }}
                    >
                      Total bookings
                    </span>
                    <span
                      style={{
                        color: "#404040",
                      }}
                    >
                      {data?.totalDocs}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#1874ce",
                      }}
                    >
                      Total pages
                    </span>
                    <span
                      style={{
                        color: "#404040",
                      }}
                    >
                      {data?.totalPages}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#1874ce",
                      }}
                    >
                      Total seats
                    </span>
                    <span
                      style={{
                        color: "#404040",
                      }}
                    >
                      {data?.selectionSeatsCount}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#1874ce",
                      }}
                    >
                      Total €
                    </span>
                    <span
                      style={{
                        color: "#404040",
                      }}
                    >
                      {data?.selectionProfit.toFixed(2)}
                    </span>
                  </div>
                </PopoverContent>
              </Popover>
            }

            // transition={false}
          >
            <Tooltip title="Selection data" placement="bottom">
              <InfoButton>
                <i className="fa-solid fa-circle-info"></i>
              </InfoButton>
            </Tooltip>
          </OverlayTrigger>
        )}
        {!isBookingFormOpen && (
          <OptionsButtonsContainer>
            <OverlayTrigger
              //container={containerRef.current} //contain within the parent element (refresh page)
              trigger="click"
              rootClose //close on click outside
              placement="left-start"
              overlay={
                <StyledPopover
                  id="popover-basic"
                  style={{
                    boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
                    position: "absolute",
                    //border: "none",
                  }}
                >
                  <ResetAllButton
                    onClick={() => {
                      setSearchText("");
                      setSelectedProductIds(
                        entities.data.products.map((product) => product._id)
                      );
                      setSelectedChannelIds(
                        entities.data.channels.map((channel) => channel._id)
                      );
                      setShowOnlyValidBookings(false);
                      setSelectedSavedFilterLabel(null);
                      setShowByEventDate(true);
                    }}
                  >
                    Reset all filters
                  </ResetAllButton>
                  <FilterItemWrapper>
                    <FilterItemContainer>
                      <FilterItem
                        onClick={() => {
                          setShowOnlyValidBookings(false);
                          setSelectedSavedFilterLabel(null);
                        }}
                      >
                        <span>Show all bookings</span>
                        {!showOnlyValidBookings && (
                          <i className="fa-solid fa-circle-check"></i>
                        )}
                      </FilterItem>
                      <FilterItem
                        onClick={() => {
                          setShowOnlyValidBookings(true);
                          setSelectedSavedFilterLabel(null);
                        }}
                      >
                        <span>Show only valid bookings</span>
                        {showOnlyValidBookings && (
                          <i className="fa-solid fa-circle-check"></i>
                        )}
                      </FilterItem>
                    </FilterItemContainer>
                    <FilterItemContainer>
                      <FilterItem
                        onClick={() => {
                          setShowByEventDate(true);
                          setSelectedSavedFilterLabel(null);
                        }}
                      >
                        <span>Sort by event date</span>
                        {showByEventDate && (
                          <i className="fa-solid fa-circle-check"></i>
                        )}
                      </FilterItem>
                      <FilterItem
                        onClick={() => {
                          setShowByEventDate(false);
                          setSelectedSavedFilterLabel(null);
                        }}
                      >
                        <span>Sort by booking date</span>
                        {!showByEventDate && (
                          <i className="fa-solid fa-circle-check"></i>
                        )}
                      </FilterItem>
                    </FilterItemContainer>
                    <FilterItemContainer>
                      <>
                        <FilterDropdown
                          header="Select products"
                          data={entities.data.products.map((product) => ({
                            label: product.title,
                            value: product._id,
                          }))}
                          selected={selectedProductIds.map((product_id) => {
                            const product = entities.data.products.find(
                              (product) => product._id === product_id
                            );
                            return {
                              label: product.title,
                              value: product._id,
                            };
                          })}
                          onSet={(product_ids) => {
                            setSelectedProductIds(
                              product_ids.map((product) => product.value)
                            );
                            setSelectedSavedFilterLabel(null);
                          }}
                        />
                        <FilterDropdown
                          header="Select channels"
                          data={entities.data.channels.map((channel) => ({
                            label: channel.title,
                            value: channel._id,
                          }))}
                          selected={selectedChannelIds.map((channel_id) => {
                            const channel = entities.data.channels.find(
                              (channel) => channel._id === channel_id
                            );
                            return {
                              label: channel.title,
                              value: channel._id,
                            };
                          })}
                          onSet={(product_ids) => {
                            setSelectedChannelIds(
                              product_ids.map((product) => product.value)
                            );
                            setSelectedSavedFilterLabel(null);
                          }}
                        />
                      </>
                    </FilterItemContainer>
                    <FilterItemContainer>
                      <FilterItem onClick={handleAddFilterClick}>
                        <span>Save filter</span>
                        <i className="fa-solid fa-floppy-disk"></i>
                      </FilterItem>

                      <SaveFiltersDropdown
                        filters={
                          JSON.parse(
                            localStorage.getItem("bookings_filters")
                          ) || []
                        }
                        selectedLabel={selectedSavedFilterLabel}
                        onSet={(filter) => {
                          setSearchText(filter.value.searchText);
                          setSelectedProductIds(filter.value.productIds);
                          setSelectedChannelIds(filter.value.channelIds);
                          setShowOnlyValidBookings(
                            filter.value.showOnlyValidBookings
                          );
                          setShowByEventDate(filter.value.showByEventDate);
                          setSelectedSavedFilterLabel(filter.label);
                        }}
                        onDelete={(filter) => {
                          if (window.confirm("Delete filter ?")) {
                            const filters = JSON.parse(
                              localStorage.getItem("bookings_filters")
                            );
                            const newFilters = filters.filter(
                              (f) => f.label !== filter.label
                            );
                            localStorage.setItem(
                              "bookings_filters",
                              JSON.stringify(newFilters)
                            );
                          }
                        }}
                      />
                    </FilterItemContainer>
                  </FilterItemWrapper>
                  <LoadButton onClick={() => refetch()}>Load</LoadButton>
                </StyledPopover>
              }
            >
              <Tooltip title="Filters" placement="left">
                <OptionsButton>
                  <i className="fa-solid fa-filter"></i>
                </OptionsButton>
              </Tooltip>
            </OverlayTrigger>
            <Tooltip title="Generate excel file" placement="left">
              <OptionsButton
                onClick={() => {
                  const bookingsHydratedWithProduct = data?.bookings.map(
                    (booking) => {
                      const product = entities?.data?.products.find(
                        (entity) => entity._id === booking.product_id
                      );
                      return { ...booking, product };
                    }
                  );

                  if (window.confirm("Generate excel file ?")) {
                    generateExcelFromBookingsManager(
                      bookingsHydratedWithProduct
                    );
                  }
                }}
              >
                <i className="fa-solid fa-file-excel"></i>
              </OptionsButton>
            </Tooltip>
          </OptionsButtonsContainer>
        )}

        {!isBookingFormOpen && (
          <AddButton
            onClick={() => {
              setBookingFormBookingId(null);
              setIsBookingFormOpen(true);
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </AddButton>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default BookingsContent;
