import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

//---------------------------------------------------------------------------

const CustomMaterialMultiSelect = ({
  values,
  selected,
  label,
  set,
}: {
  values: any;
  selected: any;
  label: string;
  set: any;
}) => {
  // console.log("values", values);
  // console.log("selected", selected);

  return (
    <FormControl
      variant="outlined"
      margin={"dense"}
      style={{ flex: 1, minWidth: 200 }}
    >
      <InputLabel id="test-select-label">{label}</InputLabel>
      <Select
        //size="medium"
        style={{ width: "100%" }}
        variant="outlined"
        onChange={(e) => {
          const clickedValue = e.target.value[e.target.value.length - 1];
          const clickedValueObject = values.find(
            (item: any) => item.value === clickedValue
          );
          const isSelected =
            selected.filter((item: any) => item.value === clickedValue).length >
            0;

          if (isSelected) {
            set(selected.filter((item: any) => item.value !== clickedValue));
          } else {
            set([...selected, clickedValueObject]);
          }
        }}
        labelId="test-select-label"
        label={label}
        color="success"
        value={selected}
        renderValue={(selected) =>
          selected.map((item: any) => item.label).join(", ")
        }
        error={selected.length === 0}
        multiple
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              width: 250,
              marginTop: 8,
            },
          },
        }}
      >
        {values.map((value: { label: string; value: any }) => (
          <MenuItem
            key={value.value}
            value={value.value}
            // selected={
            //   selected.filter((item: any) => item.value === value.value)
            //     .length > 0
            // }
          >
            <Checkbox
              checked={
                selected.filter((item: any) => item.value === value.value)
                  .length > 0
              }
            />
            <ListItemText primary={value.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMaterialMultiSelect;
