import styled from "styled-components";
import DraftsList from "./DraftsList";
import { useState } from "react";
import { useAxios } from "../../../../../axiosProvider";
import { useFetchEntities } from "../../../../../reactQueryHooks";
import AddMessageDraftModal from "./AddMessageDraftModal";

//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 300px; /* Each item takes at least 300px, can grow, won't shrink */
  max-height: 300px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(35, 139, 243);
`;

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #588637;
  color: white;
  font-weight: bold;
  font-size: 13px;
  border-radius: 5px;
  &:hover {
    background: #6e9b4f;
  }
`;

//-----------------------------------------------------------------------

const MessageDraftsOptions = () => {
  const { data: entities } = useFetchEntities();
  const [showAddDraftModal, setShowAddDraftModal] = useState(false);
  const [draftData, setDraftData] = useState({});

  const { user } = useAxios();
  const userIsPermittedToEdit =
    user.isAdmin || (user.isModerator && user.permissions.addMessageDrafts);

  const draftsSortedByTitle = entities?.data?.messageDrafts?.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <Container>
      <TitleContainer>Message drafts</TitleContainer>
      <ContentContainer>
        {entities?.data && (
          <DraftsList
            drafts={draftsSortedByTitle}
            onSelect={(draft) => {
              // simple users can be edited only by admins or permitted moderators
              if (userIsPermittedToEdit) {
                setDraftData(draft);
                setShowAddDraftModal(true);
              } else {
                alert("You have no permission to edit this draft");
              }
            }}
          />
        )}
        <AddButtonContainer>
          <AddButton
            onClick={() => {
              if (userIsPermittedToEdit) {
                setShowAddDraftModal(true);
                setDraftData({});
              } else {
                alert("You have no permission to add new drafts");
              }
            }}
            title="Add new draft"
          >
            Add Draft
          </AddButton>
        </AddButtonContainer>
      </ContentContainer>
      {showAddDraftModal && (
        <AddMessageDraftModal
          close={() => setShowAddDraftModal(false)}
          data={draftData}
        />
      )}
    </Container>
  );
};

export default MessageDraftsOptions;
