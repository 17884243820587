import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  FieldContainer,
} from "../styled";
import { useFormikContext } from "formik";
import { OverlayTrigger } from "react-bootstrap";
import viator_logo from "../Viator-Logo.png";
import gyg_logo from "../gyg_logo.png";
import bokun_logo from "../bokun_logo.png";
import Popover from "react-bootstrap/Popover";
import PromoMessageForm from "../PromoMessageForm";
import { Button } from "@mui/material"

//---------------------------------------------------

const TopButtonsSection = () => {
  const { values } = useFormikContext()

  //---------------------------------------------------

  return (
    <SectionWrapper>
      <SectionContainer
        style={{
          flexDirection: "row",
        }}
      >
        <FieldWrapper
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Button
            size="small"
            title="See booking in Viator"
            //color="secondary"
            //variant="outlined"
            style={{
              padding: "10px",
              maxWidth: "100px",
              minWidth: "100px",
              backgroundColor: "white",
            }}
            onClick={async () => {
              window.open(
                `https://supplier.viator.com/bookings/search?bookingRef=BR-${values.ref}`,
                "_blank"
              )
              // try {
              //   const url = await handleFetchingExtUrl(
              //     values.ref
              //   );
              //   setPlatformIFrameUrl(url);
              //   setShowPlatformIFrameModal(true);
              // } catch (err) {
              //   console.log(err);
              //   setPlatformIFrameUrl(
              //     err.response.data.url
              //   );
              //   setShowPlatformIFrameModal(true);
              // }
            }}
          >
            <img
              style={{
                width: "50px",
                objectFit: "contain",
              }}
              src={viator_logo}
              alt="logo"
            />
          </Button>
          <Button
            size="small"
            title="See booking in GetYourGuide"
            style={{
              maxWidth: "100px",
              minWidth: "100px",
              backgroundColor: "white",
            }}
            onClick={() => {
              window.open(
                //`https://supplier.getyourguide.com/bookings#gygCode=${values.ref}`, //old
                `https://supplier.getyourguide.com/bookings?default_page=0&filter_reference_code=${values.ref}#gygCode=${values.ref}`, //new
                "_blank"
              )
            }}
          >
            <img
              style={{
                width: "70px",
                objectFit: "contain",
              }}
              src={gyg_logo}
              alt="logo"
            />
          </Button>
          <Button
            size="small"
            title="See booking in Bokun"
            style={{
              maxWidth: "100px",
              minWidth: "100px",
              backgroundColor: "white",
            }}
            onClick={() => {
              window.open(
                `https://getaways-greece-travel.bokun.io/sales/${values.order_number}`,
                "_blank"
              )
            }}
          >
            <img
              style={{
                width: "35px",
                objectFit: "contain",
              }}
              src={bokun_logo}
              alt="logo"
            />
          </Button>

          {values?._id && (
            <OverlayTrigger
              //rootClose
              trigger="click"
              placement="auto-start"
              overlay={
                <Popover
                  style={{
                    minWidth: "400px",
                    zIndex: 1100,
                  }}
                >
                  <PromoMessageForm booking={values} />
                </Popover>
              }
            >
              <Button
                size="small"
                style={{
                  gap: "10px",
                  maxWidth: "100px",
                  minWidth: "100px",
                  backgroundColor: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "11px",
                  }}
                >
                  PROMO
                </span>
                <i
                  style={{
                    fontSize: "11px",
                  }}
                  className="fa-regular fa-message"
                ></i>
              </Button>
            </OverlayTrigger>
          )}
        </FieldWrapper>
      </SectionContainer>
    </SectionWrapper>
  )
}

export default TopButtonsSection;
