import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../../../App";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { motion } from "framer-motion";
import _ from "lodash";
import moment from "moment"
import {
  useAddNewProduct,
  useCloneProduct,
  useUpdateProduct,
  useDeleteProduct,
  useGetBokunProduct,
} from "../../../../../reactQueryHooks"
import BarLoader from "react-spinners/BarLoader"
import { useFetchEntities } from "../../../../../reactQueryHooks"
import List from "@mui/material/List"
import ListSubheader from "@mui/material/ListSubheader"
import CustomMaterialTextInput from "./CustomMaterialTextInput"
import CustomMaterialMultiSelect from "./CustomMaterialMultiSelect"
import CustomMaterialSelect from "./CustomMaterialSelect"
import CustomMaterialSwitch from "./CustomMaterialSwitch"
import ProductImagesList from "./ProductImagesList"
import { TextField } from "@mui/material"
import Box from "@mui/material/Box"
import toast from "react-hot-toast"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import Tooltip from "@mui/material/Tooltip"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt"
import Icon from "@mdi/react"
import { mdiMapSearch } from "@mdi/js"
import CloseIcon from "@mui/icons-material/Close"
import FindLocationMap from "./FindLocationMap"
import CircularProgress from "@mui/material/CircularProgress"
import "mapbox-gl/dist/mapbox-gl.css"
// @ts-ignore
mapboxgl.accessToken =
  "pk.eyJ1Ijoibm9zcGlwaSIsImEiOiJjbGVoOWc2MWMwYjVzM29xZjJkNjlnZzU3In0.ygeBpLtHj3dWNMii9EL1qw"

const timeSlotsArray = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
]

const tourTypesArray = ["Private", "Small Group"]

const tourCategoriesArray = [
  "Alternative",
  "Adventure",
  "Cultural",
  "Family",
  "Food & Drink",
  "History",
  "Nature",
  "Nightlife",
  "Relaxation",
  "Shopping",
  "Sightseeing",
  "Sports",
  "Beach",
  "Wildlife",
  "City Break",
  "Cruise",
  "Mountain",
  "Educational",
  "Photography",
  "Wellness",
  "Festival",
  "Road Trip",
  "Art & Architecture",
  "Luxury",
  "Winter Sports",
  "Spa Retreat",
  "Hiking",
  "Camping",
  "Island",
  "Business Travel",
  "Gastronomy",
  "Expedition",
  "Health & Fitness",
  "Volunteering",
  "Music & Concerts",
  "Technology",
  "Countryside",
  "Fashion",
  "Ecotourism",
  "Motorcycle Touring",
  "Rail Tour",
  "Science & Innovation",
  "Golf",
  "Amusement Parks",
  "Fishing",
  "Themed Tours",
  "Ghost Tours",
  "Theater & Performance",
  "Wine Tasting",
  "Spiritual & Religious",
  "National Parks",
  "Water Sports",
  "Student Tours",
  "Pet-Friendly",
  "Archaeological",
  "Backpacking",
  "Fashion & Shopping",
  "Astrotourism",
  "Meteorological Tours",
  "Paragliding",
  "Ziplining",
  "Educational",
  "Health Retreat",
  "Bird Watching",
  "Geological Tours",
  "Botanical Tours",
  "Hot Air Balloon",
  "Literary Tours",
  "Space Tourism",
  "Virtual Tours",
  "Film & TV Tours",
  "Architectural Tours",
  "Shopping",
  "Desert Safari",
  "Skating",
  "Winter Wonderland",
  "Bike Tour",
  "Literary Tours",
  "Culinary Tour",
  "Rural Tourism",
  "Farming Experience",
  "Digital Nomad Tours",
  "Arts & Crafts",
  "Holiday Markets",
  "Cultural Immersion",
  "Mindfulness Retreat",
]

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .MuiInputLabel-shrink {
    //font-size: 15px;
  }
  /* 
  .MuiInputLabel-root{
    font-size: 12px;
  } */
`

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 90%;
  max-width: 1700px;
  height: 90%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow-y: auto;
  input {
    font-size: 13px;
  }
  //target all input placeholders
  input::placeholder {
    font-size: 10px;
  }
`

// const Wrapper = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 1000;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
// `;

// const Modal = styled(motion.div)`
//   background-color: white;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 10px;
//   gap: 10px;
//   width: 90%;
//   height: 90%;
//   max-width: 650px;
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   gap: 10px;
//   height: 100%;
//   overflow-y: auto;
//   padding: 0 5px 05px 0;
// `;

const CloseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`

//---------------------------------------------------------
interface Errors {
  [key: string]: string | Errors
}

function flattenErrors(obj: Errors, parentKey: string = ""): string[] {
  let result: string[] = []
  for (let key in obj) {
    let currentKey = parentKey ? `${parentKey}.${key}` : key
    if (typeof obj[key] === "object") {
      result = result.concat(flattenErrors(obj[key] as Errors, currentKey))
    } else {
      result.push(obj[key] as string)
    }
  }
  return result
}

const AddProductsModal = ({
  close,
  data,
}: {
  close: () => void
  data: any
}) => {
  const {
    data: entities,
    refetch: refetchEntities,
  }: {
    data: any
    refetch: () => void
  } = useFetchEntities()

  const { mutateAsync: getBokunProduct } = useGetBokunProduct() as any

  const scrollableContainerRef = useRef<HTMLDivElement | null>(null)

  const groups = entities?.data?.groups || []
  const roles = entities?.data?.roles || []
  const meetingPoints = entities?.data?.meetingPoints || []

  const { mutateAsync: addNewProduct, isLoading: isAddingProduct } =
    useAddNewProduct() as any

  const { mutateAsync: cloneProduct } = useCloneProduct() as any

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } =
    useUpdateProduct() as any

  const { mutateAsync: deleteProduct } = useDeleteProduct() as any

  const appContext = useContext(AppContext)
  const { googleInstance } = appContext
  const [isPickupLocationFindLoading, setIsPickupLocationFindLoading] =
    useState(false)

  //scroll ContentContainer to top on open

  useEffect(() => {
    //scroll to top
    const contentContainer = document.getElementById(
      "product-modal-container"
    ) as HTMLDivElement
    setTimeout(() => {
      contentContainer.scrollTo({ top: 0, behavior: "smooth" })
    }, 1500)
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.scrollTop = 0
      }
    }, 50)
  }, [])

  //---------------------------------------------------------------------------

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        title: data?.title || "",
        platform_product_name: data?.platform_product_name || "",
        product_short_description: data?.product_short_description || "",
        product_full_description: data?.product_full_description || "",
        guide_assignment_identifier: data?.guide_assignment_identifier || "",
        pricing_options: data?.pricing_options || [],
        options: data?.options || [
          {
            title: "",
            bokun_code: "",
            is_private: false,
            is_guided: false,
            requires_vehicle: false,
            requires_platformEntry: false,
          },
        ],
        time_slots: data?.time_slots || [],
        time_slots_with_range: data?.time_slots_with_range || [],
        tour_types: data?.tour_types || [],
        activity_level: data?.activity_level || "",
        isPublished: data?.isPublished || false,
        isPrivate: data?.isPrivate || false,
        isGuided: data?.isGuided || false,
        pickupIncluded: data?.pickupIncluded || false,
        crewGroups: data?.crewGroups || [],
        crewRoles: data?.crewRoles || [],
        additional_info: data?.additional_info || [],
        highlights: data?.highlights || [],
        special_instructions: data?.special_instructions || [],
        destinations: data?.destinations || [],
        meeting_point_id: data?.meeting_point_id || "",
        tour_duration: data?.tour_duration || "",
        tour_duration_type: data?.tour_duration_type || "",
        tour_categories: data?.tour_categories || [],
        inclusions: data?.inclusions || [],
        exclusions: data?.exclusions || [],
        review_link: data?.review_link || "",
        affiliate_link: data?.affiliate_link || "",
        bokun_product_code: data?.bokun_product_code || "",
        market_price: data?.market_price || 0,
        product_pictures: data?.product_pictures || [],
        location: data?.location || {
          address: "",
          latitude: 37.975379384130804,
          longitude: 23.738300169553295,
        },
        index: data?.index || 1,
      }}
      validationSchema={Yup.object({
        title: Yup.string().required("Identifier is required"),
        platform_product_name: Yup.string().required(
          "Platform title is required"
        ),
        product_short_description: Yup.string().required(
          "Short description is required"
        ),
        product_full_description: Yup.string().required(
          "Full description is required"
        ),
        pricing_options: Yup.array().min(
          1,
          "At least one pricing option is required"
        ),
        //options - at least one, title and bokun_code should be required
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required("Option title is required"),
              bokun_code: Yup.string().required("Bokun code is required"),
            })
          )
          .min(1, "At least one option is required"),
        time_slots: Yup.array().min(1, "At least one time slot is required"),
        time_slots_with_range: Yup.array()
          .of(
            Yup.object().shape({
              time_slot: Yup.string().required("Time slot is required"),
              label: Yup.string().required("Time slot label is required"),
              bokun_start_time_id: Yup.string().required(
                "Bokun start time id is required"
              ),
              date_ranges: Yup.array().of(
                Yup.object().shape({
                  start: Yup.string().required(
                    "Start date is required in time slot"
                  ),
                  end: Yup.string().required(
                    "End date is required in time slot"
                  ),
                })
              ),
            })
          )
          .min(1, "At least one time slot is required"),
        activity_level: Yup.string().required("Activity level is required"),
        crewGroups: Yup.array().min(1, "At least one crew group is required"),
        crewRoles: Yup.array().min(1, "At least one crew role is required"),
        tour_duration: Yup.string().required("Tour duration is required"),
        tour_duration_type: Yup.string().required(
          "Tour duration type is required"
        ),
        review_link: Yup.string()
          .url("Invalid review link URL")
          .required("Review link is required"),
        affiliate_link: Yup.string().url("Invalid affiliate link URL"),
        bokun_product_code: Yup.string().required(
          "Bokun product code is required"
        ),
        market_price: Yup.number().required("Market price is required"),
        location: Yup.object().shape({
          address: Yup.string().required("Address is required"),
          latitude: Yup.number().required("Latitude is required"),
          longitude: Yup.number().required("Longitude is required"),
        }),
        index: Yup.number().required("Position index is required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        const isExistingProduct = data?._id ? true : false

        try {
          if (isExistingProduct) {
            const response = await updateProduct({
              id: data?._id,
              payload: values,
            })
            alert(response?.data)
            refetchEntities()
          } else {
            const response = await addNewProduct(values)
            alert(response?.data)
            refetchEntities()
          }
        } catch (error: any) {
          alert(error?.response?.data || error.toString())
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        const handleFindPickupLocation = async () => {
          try {
            if (!values.location.address) {
              alert("Please enter a address")
              return
            }

            if (!googleInstance) {
              alert("Google Maps API has trouble loading ! Please check logs")
              return
            }
            setIsPickupLocationFindLoading(true)
            const geocoder = new googleInstance.Geocoder()
            geocoder.geocode(
              { address: values.location.address },
              (results: any, status: any) => {
                if (status === "OK") {
                  //const formattedAddress = results[0].formatted_address;
                  const location = results[0].geometry.location
                  setFieldValue("location.latitude", location.lat())
                  setFieldValue("location.longitude", location.lng())
                  //setFieldValue("location.address", formattedAddress);
                  setIsPickupLocationFindLoading(false)
                } else {
                  setIsPickupLocationFindLoading(false)
                  alert(status)
                }
              }
            )
          } catch (error: any) {
            setIsPickupLocationFindLoading(false)
            console.log(error)
            alert(error.toString())
          }
        }

        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  //type: "spring",
                  // stiffness: 300, // Adjust stiffness for more/less bounce
                  // damping: 20, // Adjust damping for faster/slower settle
                  // duration: 0.02,
                },
              }}
            >
              <CloseContainer id="product-modal-container">
                {data?._id && (
                  <Tooltip title="Delete product">
                    <IconButton
                      size="medium"
                      onClick={async () => {
                        try {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this product?"
                            )
                          ) {
                            const userConfirmation = prompt(
                              "Please type product identifier to confirm"
                            )

                            if (userConfirmation === values.title) {
                              const response = await deleteProduct(data?._id)
                              //@ts-ignore
                              alert(response?.data)
                              refetchEntities()
                              close()
                            } else {
                              alert("Incorrect, try again")
                            }
                          }
                        } catch (error: any) {
                          toast.error(
                            error?.response?.data || error.toString(),
                            {
                              position: "bottom-center", // Set position to bottom center
                            }
                          )
                        }
                      }}
                    >
                      <DeleteIcon fontSize="inherit" color="warning" />
                    </IconButton>
                  </Tooltip>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                  }}
                >
                  {data?._id && (
                    <Tooltip title="Clone product">
                      <IconButton
                        size="medium"
                        onClick={async () => {
                          try {
                            if (
                              window.confirm(
                                "Are you sure you wish to clone this product?"
                              )
                            ) {
                              const response = await cloneProduct(data?._id)
                              //@ts-ignore
                              alert(response?.data)
                              refetchEntities()
                              close()
                            }
                          } catch (error) {
                            alert(error?.toString())
                          }
                        }}
                      >
                        <ContentCopyIcon fontSize="inherit" color="info" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Sync with Bokun">
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      onClick={async () => {
                        //if bokun product code is not provided, then alert user to provide it
                        if (!values.bokun_product_code) {
                          alert("Bokun product code is required")
                          return
                        }

                        try {
                          const bokunProduct = await getBokunProduct(
                            values.bokun_product_code
                          )
                          const start_times = bokunProduct?.data?.startTimes
                          const isPrivateActivity =
                            bokunProduct?.data?.privateActivity
                          const pickupIncluded =
                            bokunProduct?.data?.pickupService
                          const title = bokunProduct?.data?.externalId
                          const platformProductName = bokunProduct?.data?.title
                          const label = isPrivateActivity
                            ? "Private Tour"
                            : "Small Group Tour"
                          const product_short_description =
                            bokunProduct?.data?.description
                              .replace(/<\/?[^>]+(>|$)/g, "")
                              .replace(/&#39;/g, "'")
                          const product_full_description =
                            bokunProduct?.data?.excerpt
                          const highlights =
                            bokunProduct?.data?.agendaItems.map(
                              (item: any) => item.title
                            )
                          const review_link =
                            bokunProduct?.data?.tripadvisorReview?.url
                          const pricing_options =
                            bokunProduct?.data?.pricingCategories.map(
                              (category: any) => category.title
                            )
                          const activity_level =
                            bokunProduct?.data?.difficultyLevel
                          const tour_duration = bokunProduct?.data?.durationText

                          const options = bokunProduct?.data?.rates.map(
                            (rate: any) => {
                              return {
                                title: rate.title,
                                bokun_code: rate.id,
                                is_private: isPrivateActivity,
                                is_guided: false,
                                requires_vehicle: true,
                                requires_platformEntry: true,
                              }
                            }
                          )

                          setFieldValue(
                            "time_slots_with_range",
                            start_times.map((time: any) => {
                              // const time = use moment to format the time
                              const formattedTime = moment(
                                `${time.hour}:${time.minute}`,
                                "HH:mm"
                              ).format("HH:mm")
                              return {
                                time_slot: formattedTime,
                                label: time.label || label,
                                bokun_start_time_id: time.id,
                                date_ranges: [
                                  {
                                    start: moment().format("YYYY-MM-DD"),
                                    end: "2100-01-01",
                                  },
                                ],
                              }
                            })
                          )

                          setFieldValue("options", options)
                          setFieldValue("isPrivate", isPrivateActivity)
                          setFieldValue("pickupIncluded", pickupIncluded)
                          setFieldValue("title", title)
                          setFieldValue(
                            "platform_product_name",
                            platformProductName
                          )
                          setFieldValue(
                            "product_short_description",
                            product_short_description
                          )
                          setFieldValue(
                            "product_full_description",
                            product_full_description
                          )
                          setFieldValue("highlights", highlights)
                          setFieldValue("review_link", review_link)
                          setFieldValue("pricing_options", pricing_options)
                          setFieldValue("activity_level", activity_level)
                          setFieldValue("tour_duration", tour_duration)
                        } catch (error) {
                          alert(error?.toString())
                        }
                      }}
                    >
                      <SystemUpdateAltIcon fontSize="inherit" color="info" />
                    </IconButton>
                  </Tooltip>
                </div>
                <IconButton aria-label="delete" size="medium" onClick={close}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </CloseContainer>
              <ContentContainer ref={scrollableContainerRef}>
                <CustomMaterialTextInput
                  label={"Bokun product code"}
                  name={"bokun_product_code"}
                  value={values.bokun_product_code}
                  error={errors.bokun_product_code ? true : false}
                  handleChange={handleChange}
                />
                <CustomMaterialTextInput
                  label={"Product identifier"}
                  name={"title"}
                  value={values.title}
                  error={errors.title ? true : false}
                  handleChange={handleChange}
                />
                <CustomMaterialTextInput
                  label={"Platform title"}
                  name={"platform_product_name"}
                  value={values.platform_product_name}
                  error={errors.platform_product_name ? true : false}
                  handleChange={handleChange}
                />
                <CustomMaterialTextInput
                  label={"Product short description"}
                  name={"product_short_description"}
                  value={values.product_short_description}
                  error={errors.product_short_description ? true : false}
                  handleChange={handleChange}
                />
                <CustomMaterialTextInput
                  label={"Product full description"}
                  name={"product_full_description"}
                  value={values.product_full_description}
                  error={errors.product_full_description ? true : false}
                  handleChange={handleChange}
                />
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.options.length > 0 ? "black" : "indianred",
                      }}
                    >
                      OPTIONS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("options", [
                            ...values.options,
                            {
                              title: "",
                              bokun_code: "",
                            },
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.options.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 2,
                          flexWrap: "wrap",
                          backgroundColor: "rgb(250 250 250)",
                          padding: 2,
                          borderRadius: 1,
                          border: "1px solid lightgray",
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Option ${index + 1} title`}
                          name={`options.${index}.title`}
                          value={item.title}
                          error={errors.options ? true : false}
                          handleChange={handleChange}
                        />
                        <CustomMaterialTextInput
                          label={`Option ${index + 1} bokun code`}
                          name={`options.${index}.bokun_code`}
                          value={item.bokun_code}
                          error={errors.options ? true : false}
                          handleChange={handleChange}
                        />
                        <CustomMaterialSwitch
                          label={"Private"}
                          checked={item.is_private}
                          set={(e: any) => {
                            setFieldValue(
                              `options.${index}.is_private`,
                              e.target.checked
                            )
                          }}
                        />
                        <CustomMaterialSwitch
                          label={"Guided"}
                          checked={item.is_guided}
                          set={(e: any) => {
                            setFieldValue(
                              `options.${index}.is_guided`,
                              e.target.checked
                            )
                          }}
                        />
                        <CustomMaterialSwitch
                          label={"Requires vehicle"}
                          checked={item.requires_vehicle}
                          set={(e: any) => {
                            setFieldValue(
                              `options.${index}.requires_vehicle`,
                              e.target.checked
                            )
                          }}
                        />
                        <CustomMaterialSwitch
                          label={"Requires platform entry"}
                          checked={item.requires_platformEntry}
                          set={(e: any) => {
                            setFieldValue(
                              `options.${index}.requires_platformEntry`,
                              e.target.checked
                            )
                          }}
                        />

                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            //remove the option of index, but if its the only option, then alert user "At least one option is required"
                            if (values.options.length === 1) {
                              alert("At least one option is required")
                              return
                            }
                            setFieldValue(
                              "options",
                              values.options.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomMaterialMultiSelect
                    values={[
                      {
                        label: "Adult",
                        value: "Adult",
                      },
                      {
                        label: "Child",
                        value: "Child",
                      },
                      {
                        label: "Infant",
                        value: "Infant",
                      },
                      {
                        label: "Senior",
                        value: "Senior",
                      },
                      {
                        label: "Youth",
                        value: "Youth",
                      },
                    ]}
                    selected={values.pricing_options.map((option: any) => ({
                      label: option,
                      value: option,
                    }))}
                    label={"Pricing options"}
                    set={(value: any) => {
                      setFieldValue(
                        "pricing_options",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                  <CustomMaterialMultiSelect
                    values={timeSlotsArray.map((timeSlot: any) => ({
                      label: timeSlot,
                      value: timeSlot,
                    }))}
                    selected={_.sortBy(
                      values.time_slots.map(
                        (timeSlot: { label: string; value: string }) => ({
                          label: timeSlot,
                          value: timeSlot,
                        })
                      ),
                      "value"
                    )}
                    label={"Time slots"}
                    set={(value: any) => {
                      setFieldValue(
                        "time_slots",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                </Box>
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    flexWrap: "wrap",
                    backgroundColor: "rgb(250 250 250)",
                    padding: 2,
                    borderRadius: 1,
                    border: "1px solid lightgray",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.time_slots_with_range.length > 0
                            ? "black"
                            : "indianred",
                      }}
                    >
                      TIME SLOTS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          //ADD A TIME SLOT WITH EMPTY DATE RANGES
                          setFieldValue("time_slots_with_range", [
                            ...values.time_slots_with_range,
                            {
                              time_slot: "",
                              date_ranges: [
                                {
                                  start: "",
                                  end: "",
                                },
                              ],
                            },
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.time_slots_with_range.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 1,
                              flex: 1,
                              width: "100%",
                              flexWrap: "wrap",
                              backgroundColor: "rgb(240 240 240)",
                              padding: 2,
                              borderRadius: 1,
                              border: "1px solid lightgray",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 1,
                                width: "100%",
                                //wrap content
                                flexWrap: "wrap",
                              }}
                            >
                              <CustomMaterialSelect
                                values={timeSlotsArray.map((timeSlot: any) => ({
                                  label: timeSlot,
                                  value: timeSlot,
                                }))}
                                selected={{
                                  label: item.time_slot,
                                  value: item.time_slot,
                                }}
                                label={`Time slot ${index + 1}`}
                                set={(value: any) => {
                                  setFieldValue(
                                    `time_slots_with_range.${index}.time_slot`,
                                    value.value
                                  );
                                }}
                                error={false}
                              />
                              <CustomMaterialTextInput
                                label={"Time slot label"}
                                name={`time_slots_with_range.${index}.label`}
                                value={item.label}
                                error={
                                  errors.time_slots_with_range ? true : false
                                }
                                handleChange={handleChange}
                              />
                              <CustomMaterialTextInput
                                label={"Bokun start time id"}
                                name={`time_slots_with_range.${index}.bokun_start_time_id`}
                                value={item.bokun_start_time_id}
                                error={
                                  errors.time_slots_with_range ? true : false
                                }
                                handleChange={handleChange}
                              />
                              <CustomMaterialSwitch
                                label={"Default pickup time"}
                                checked={item.isDefaultPickupTime}
                                set={(e: any) => {
                                  setFieldValue(
                                    `time_slots_with_range.${index}.isDefaultPickupTime`,
                                    e.target.checked
                                  );
                                }}
                              />
                              <IconButton
                                aria-label="delete"
                                color="warning"
                                size="medium"
                                onClick={() => {
                                  setFieldValue(
                                    "time_slots_with_range",
                                    values.time_slots_with_range.filter(
                                      (item: any, i: number) => i !== index
                                    )
                                  );
                                }}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                            <List
                              sx={{
                                flex: 1,
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",

                                gap: 2,
                              }}
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                              subheader={
                                <ListSubheader
                                  component="span"
                                  sx={{
                                    alignSelf: "flex-end",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: 1,
                                    // color:
                                    //   values.highlights.length > 0 ? "black" : "indianred",
                                    color: "black",
                                  }}
                                >
                                  {`DATE RANGES for ${item.time_slot}`}
                                  <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    onClick={() => {
                                      //ADD A RANGE TO THE DATE RANGES IN TIME SLOT
                                      setFieldValue(
                                        `time_slots_with_range.${index}.date_ranges`,
                                        [
                                          ...values.time_slots_with_range[index]
                                            .date_ranges,
                                          {
                                            start: "",
                                            end: "",
                                          },
                                        ]
                                      );
                                    }}
                                  >
                                    <AddCircleIcon fontSize="inherit" />
                                  </IconButton>
                                </ListSubheader>
                              }
                            >
                              {item.date_ranges.map(
                                (dateRange: any, dateIndex: number) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <TextField
                                        id="date"
                                        label="Start date"
                                        type="date"
                                        value={dateRange.start}
                                        onChange={(e) => {
                                          const startDate = moment(
                                            e.target.value
                                          );
                                          const endDate = moment(dateRange.end);
                                          // const dateIsBetweenAnotherRange = is value date between another date range

                                          // const dateIsBetweenAnotherRange =
                                          //   item.date_ranges
                                          //     .filter(
                                          //       (otherItem: any) =>
                                          //         otherItem !== item
                                          //     ) // Exclude the current item
                                          //     .some((dateRange: any) =>
                                          //       moment(
                                          //         e.target.value
                                          //       ).isBetween(
                                          //         moment(dateRange.start),
                                          //         moment(dateRange.end),
                                          //         undefined,
                                          //         "[]" // Set inclusive brackets to include the start and end dates
                                          //       )
                                          //     );

                                          // if (dateIsBetweenAnotherRange) {
                                          //   alert(
                                          //     `This date range is between another date range of ${item.time_slot} time slot`
                                          //   );
                                          //   return;
                                          // }
                                          if (startDate.isAfter(endDate)) {
                                            alert(
                                              "Start date cannot be after end date"
                                            );
                                            return;
                                          }

                                          setFieldValue(
                                            `time_slots_with_range.${index}.date_ranges.${dateIndex}.start`,
                                            e.target.value
                                          );
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          flex: 1,
                                        }}
                                      />
                                      <TextField
                                        id="date"
                                        label="End date"
                                        type="date"
                                        value={dateRange.end}
                                        onChange={(e) => {
                                          const startDate = moment(
                                            dateRange.start
                                          );
                                          const endDate = moment(
                                            e.target.value
                                          );
                                          // const dateIsBetweenAnotherRange =
                                          //   item.date_ranges
                                          //     .filter(
                                          //       (otherItem: any) =>
                                          //         otherItem !== item
                                          //     ) // Exclude the current item
                                          //     .some((dateRange: any) =>
                                          //       moment(
                                          //         e.target.value
                                          //       ).isBetween(
                                          //         moment(dateRange.start),
                                          //         moment(dateRange.end),
                                          //         undefined,
                                          //         "[]" // Set inclusive brackets to include the start and end dates
                                          //       )
                                          //     );

                                          // if (dateIsBetweenAnotherRange) {
                                          //   alert(
                                          //     `This date range is between another date range of ${item.time_slot} time slot`
                                          //   );
                                          //   return;
                                          // }

                                          if (endDate.isBefore(startDate)) {
                                            alert(
                                              "End date cannot be before start date"
                                            );
                                            return;
                                          }
                                          setFieldValue(
                                            `time_slots_with_range.${index}.date_ranges.${dateIndex}.end`,
                                            e.target.value
                                          );
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          flex: 1,
                                        }}
                                      />
                                      <IconButton
                                        aria-label="delete"
                                        color="warning"
                                        size="medium"
                                        onClick={() => {
                                          setFieldValue(
                                            `time_slots_with_range.${index}.date_ranges`,
                                            values.time_slots_with_range[
                                              index
                                            ].date_ranges.filter(
                                              (item: any, i: number) =>
                                                i !== dateIndex
                                            )
                                          );
                                        }}
                                      >
                                        <DeleteIcon fontSize="inherit" />
                                      </IconButton>
                                    </Box>
                                  );
                                }
                              )}
                            </List>
                          </Box>
                          <Divider
                            component="span"
                            variant="middle"
                            sx={{
                              marginTop: 2,
                              marginBottom: 2,
                            }}
                          />
                        </>
                      );
                    }
                  )}
                </List>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomMaterialMultiSelect
                    values={groups.map((group: any) => {
                      return {
                        label: group.title,
                        value: group._id,
                      }
                    })}
                    selected={values.crewGroups.map((groupId: any) => {
                      const thisGroup = groups.find(
                        (item: any) => item._id === groupId
                      )
                      return {
                        label: thisGroup.title,
                        value: thisGroup._id,
                      }
                    })}
                    label={"Crew groups"}
                    set={(value: any) => {
                      setFieldValue(
                        "crewGroups",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                  <CustomMaterialMultiSelect
                    values={roles.map((role: any) => {
                      return {
                        label: role.title,
                        value: role._id,
                      }
                    })}
                    selected={values.crewRoles.map((roleId: any) => {
                      const thisRole = roles.find(
                        (item: any) => item._id === roleId
                      )
                      return {
                        label: thisRole.title,
                        value: thisRole._id,
                      }
                    })}
                    label={"Crew roles"}
                    set={(value: any) => {
                      setFieldValue(
                        "crewRoles",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <CustomMaterialTextInput
                    label={"Tour duration"}
                    name={"tour_duration"}
                    value={values.tour_duration}
                    error={errors.tour_duration ? true : false}
                    handleChange={handleChange}
                  />
                  <CustomMaterialSelect
                    values={[
                      {
                        label: "Half Day Tour",
                        value: "Half Day Tour",
                      },
                      {
                        label: "Full Day Tour",
                        value: "Full Day Tour",
                      },
                      {
                        label: "Multi Day Tour",
                        value: "Multi Day Tour",
                      },
                    ]}
                    selected={{
                      label: values.tour_duration_type,
                      value: values.tour_duration_type,
                    }}
                    label={"Tour duration type"}
                    set={(value: any) => {
                      setFieldValue("tour_duration_type", value.value)
                    }}
                    error={errors.tour_duration_type ? true : false}
                  />
                  <CustomMaterialMultiSelect
                    values={tourTypesArray.map((type: any) => ({
                      label: type,
                      value: type,
                    }))}
                    selected={values.tour_types.map(
                      (type: { label: string; value: string }) => ({
                        label: type,
                        value: type,
                      })
                    )}
                    label={"Tour types"}
                    set={(value: any) => {
                      setFieldValue(
                        "tour_types",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                  <CustomMaterialSelect
                    values={[
                      {
                        label: "Easy",
                        value: "Easy",
                      },
                      {
                        label: "Moderate",
                        value: "Moderate",
                      },
                      {
                        label: "Challenging",
                        value: "Challenging",
                      },
                    ]}
                    selected={{
                      label: values.activity_level,
                      value: values.activity_level,
                    }}
                    label={"Activity level"}
                    set={(value: any) => {
                      setFieldValue("activity_level", value.value)
                    }}
                    error={errors.activity_level ? true : false}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <CustomMaterialMultiSelect
                    values={tourCategoriesArray.map((type: any) => ({
                      label: type,
                      value: type,
                    }))}
                    selected={values.tour_categories.map(
                      (type: { label: string; value: string }) => ({
                        label: type,
                        value: type,
                      })
                    )}
                    label={"Tour categories"}
                    set={(value: any) => {
                      setFieldValue(
                        "tour_categories",
                        value.map((item: any) => item.value)
                      )
                    }}
                  />
                  <CustomMaterialTextInput
                    label={"Market price"}
                    name={"market_price"}
                    value={values.market_price}
                    error={errors.market_price ? true : false}
                    handleChange={handleChange}
                  />
                </Box>
                <CustomMaterialTextInput
                  label={"Guide assignment identifier"}
                  name={"guide_assignment_identifier"}
                  value={values.guide_assignment_identifier}
                  error={errors.guide_assignment_identifier ? true : false}
                  handleChange={handleChange}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <CustomMaterialSwitch
                    label={"Private"}
                    checked={values.isPrivate}
                    set={(e: any) => {
                      setFieldValue("isPrivate", e.target.checked)
                    }}
                  />
                  <CustomMaterialSwitch
                    label={"Guided"}
                    checked={values.isGuided}
                    set={(e: any) => {
                      setFieldValue("isGuided", e.target.checked)
                    }}
                  />
                </Box>
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />

                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.highlights.length > 0 ? "black" : "indianred",
                      }}
                    >
                      HIGHLIGHTS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("highlights", [
                            ...values.highlights,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.highlights.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Highlight ${index + 1}`}
                          name={`highlights.${index}`}
                          value={item}
                          error={errors.highlights ? true : false}
                          handleChange={handleChange}
                        />
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            setFieldValue(
                              "highlights",
                              values.highlights.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>
                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />

                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.additional_info.length > 0
                            ? "black"
                            : "indianred",
                      }}
                    >
                      ADDITIONAL INFO
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("additional_info", [
                            ...values.additional_info,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.additional_info.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Additional info ${index + 1}`}
                          name={`additional_info.${index}`}
                          value={item}
                          error={errors.additional_info ? true : false}
                          handleChange={handleChange}
                        />
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            setFieldValue(
                              "additional_info",
                              values.additional_info.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>

                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.special_instructions.length > 0
                            ? "black"
                            : "indianred",
                      }}
                    >
                      SPECIAL INSTRUCTIONS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("special_instructions", [
                            ...values.special_instructions,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.special_instructions.map(
                    (item: any, index: number) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <CustomMaterialTextInput
                            label={`Special instruction ${index + 1}`}
                            name={`special_instructions.${index}`}
                            value={item}
                            error={errors.special_instructions ? true : false}
                            handleChange={handleChange}
                          />
                          <IconButton
                            aria-label="delete"
                            color="warning"
                            size="medium"
                            onClick={() => {
                              setFieldValue(
                                "special_instructions",
                                values.special_instructions.filter(
                                  (item: any, i: number) => i !== index
                                )
                              )
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      )
                    }
                  )}
                </List>
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.destinations.length > 0
                            ? "black"
                            : "indianred",
                      }}
                    >
                      DESTINATIONS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("destinations", [
                            ...values.destinations,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.destinations.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Destination ${index + 1}`}
                          name={`destinations.${index}`}
                          value={item}
                          error={errors.destinations ? true : false}
                          handleChange={handleChange}
                        />
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            setFieldValue(
                              "destinations",
                              values.destinations.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>
                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.inclusions.length > 0 ? "black" : "indianred",
                      }}
                    >
                      INCLUSIONS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("inclusions", [
                            ...values.inclusions,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.inclusions.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Inclusion ${index + 1}`}
                          name={`inclusions.${index}`}
                          value={item}
                          error={errors.inclusions ? true : false}
                          handleChange={handleChange}
                        />
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            setFieldValue(
                              "inclusions",
                              values.inclusions.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>
                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <List
                  sx={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                        color:
                          values.exclusions.length > 0 ? "black" : "indianred",
                      }}
                    >
                      EXCLUSIONS
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => {
                          setFieldValue("exclusions", [
                            ...values.exclusions,
                            "",
                          ])
                        }}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </ListSubheader>
                  }
                >
                  {values.exclusions.map((item: any, index: number) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <CustomMaterialTextInput
                          label={`Exclusion ${index + 1}`}
                          name={`exclusions.${index}`}
                          value={item}
                          error={errors.exclusions ? true : false}
                          handleChange={handleChange}
                        />
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          size="medium"
                          onClick={() => {
                            setFieldValue(
                              "exclusions",
                              values.exclusions.filter(
                                (item: any, i: number) => i !== index
                              )
                            )
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )
                  })}
                </List>
                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <CustomMaterialTextInput
                  label={"Review link"}
                  name={"review_link"}
                  value={values.review_link}
                  error={errors.review_link ? true : false}
                  handleChange={handleChange}
                />
                <CustomMaterialTextInput
                  label={"Affiliate link"}
                  name={"affiliate_link"}
                  value={values.affiliate_link}
                  error={errors.affiliate_link ? true : false}
                  handleChange={handleChange}
                />
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                {data?._id && <ProductImagesList productId={data?._id} />}
                <Divider
                  component="span"
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomMaterialSwitch
                    label={"Pickup Included"}
                    checked={values.pickupIncluded}
                    set={(e: any) => {
                      setFieldValue("pickupIncluded", e.target.checked)
                    }}
                  />
                  <CustomMaterialSelect
                    values={meetingPoints.map((meetingPoint: any) => {
                      return {
                        label: meetingPoint.name,
                        value: meetingPoint._id,
                      }
                    })}
                    selected={{
                      label: meetingPoints.find(
                        (item: any) => item._id === values.meeting_point_id
                      )?.name,
                      value: values.meeting_point_id,
                    }}
                    label={"Meeting point"}
                    set={(value: any) => {
                      setFieldValue("meeting_point_id", value.value)
                    }}
                    error={errors.meeting_point_id ? true : false}
                  />
                </Box>

                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />

                <ListSubheader
                  component="span"
                  sx={{
                    alignSelf: "flex-end",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  LOCATION
                </ListSubheader>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <CustomMaterialTextInput
                    label={"Address"}
                    name={"location.address"}
                    value={values.location.address}
                    error={errors.location ? true : false}
                    handleChange={handleChange}
                  />
                  <IconButton
                    style={{
                      alignSelf: "center",
                    }}
                    aria-label="delete"
                    size="medium"
                    onClick={handleFindPickupLocation}
                  >
                    {isPickupLocationFindLoading ? (
                      <CircularProgress color="primary" size={"25px"} />
                    ) : (
                      <Icon path={mdiMapSearch} size={1.5} />
                    )}
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    flexWrap: "wrap",
                    marginBottom: 1,
                  }}
                >
                  <CustomMaterialTextInput
                    label={"Latitude"}
                    name={"location.latitude"}
                    value={values.location.latitude}
                    error={errors.location ? true : false}
                    handleChange={handleChange}
                  />
                  <CustomMaterialTextInput
                    label={"Longitude"}
                    name={"location.longitude"}
                    value={values.location.longitude}
                    error={errors.location ? true : false}
                    handleChange={handleChange}
                  />
                </Box>
                <FindLocationMap />

                <Divider
                  variant="middle"
                  component="span"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <CustomMaterialSwitch
                  label={"Published"}
                  checked={values.isPublished}
                  set={(e: any) => {
                    setFieldValue("isPublished", e.target.checked)
                  }}
                />
                <CustomMaterialTextInput
                  label={"Position index"}
                  name={"index"}
                  value={values.index}
                  error={errors.index ? true : false}
                  handleChange={handleChange}
                />
              </ContentContainer>
              <ButtonWrapper>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    resetForm()
                    setTimeout(() => {
                      validateForm()
                    }, 100)
                  }}
                >
                  CLEAR
                </Button>

                <Button
                  style={{
                    flex: 1,
                  }}
                  variant="contained"
                  size="large"
                  color="success"
                  type="submit"
                  title="Submit"
                  onClick={() => {
                    if (Object.keys(errors).length > 0) {
                      validateForm()
                      let errorStrings: string[] = flattenErrors(errors as any)
                      let joinedString: string = errorStrings.join("\n")
                      alert(joinedString)
                    } else {
                      if (
                        window.confirm(
                          "Submit ? \nPlease make sure all the data is correct"
                        )
                      ) {
                        handleSubmit()
                      }
                    }
                  }}
                >
                  {isUpdatingProduct || isAddingProduct ? (
                    <BarLoader color="#606060" loading={true} />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </ButtonWrapper>
            </Modal>
          </Wrapper>
        )
      }}
    </Formik>
  )
}

export default AddProductsModal;
