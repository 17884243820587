import { useEffect, useCallback, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import BarLoader from "react-spinners/BarLoader";
import {
  useFetchEntities,
  useGetBookingById,
  useAddNewBooking,
  useDuplicateBooking,
  useUpdateBooking,
  useDeleteBooking,
} from "../../../reactQueryHooks"
import { RotatingLines } from "react-loader-spinner"
import { useLocation } from "react-router-dom"
import { useAxios } from "../../../axiosProvider"
import BookingHistory from "./BookingHistory"
import _ from "lodash"
import BookingPortalLogs from "./BookingPortalLogs"
import CloseIcon from "@mui/icons-material/Close"
import {
  FormModal,
  Container,
  SpinnerContainer,
  FormContainer,
  Form,
  TopContainer,
  ButtonsContainer,
} from "./styled"
import Button from "@mui/material/Button"
import moment from "moment"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { getDefaultBookingData } from "./defaultBookingData"
import TopButtonsSection from "./sections/TopButtonsSection"
import BookingInfoSection from "./sections/BookingInfoSection"
import ClientInfoSection from "./sections/ClientInfoSection"
import ClientLocationSection from "./sections/ClientLocationSection"
import PickupLocationSection from "./sections/PickupLocationSection"

//----------------------------------------------------------------------------

const yupValidationSchema = Yup.object().shape({
  ref: Yup.string().required("Reference number is required"),
  channel_id: Yup.string().required("Channel is required"),
  date: Yup.date().required("Date is required"),
  product_id: Yup.string(),
  option_id: Yup.string().required("Product option is required"),
  product_time_slot: Yup.string().required("Time slot is required"),
  start_time_id: Yup.string().required("Start time id is required"),
  client_name: Yup.string().required("Client name is required"),
  client_email: Yup.string().email("Invalid email"),
  client_phone: Yup.string(),
  tickets: Yup.object(),
  billing_codes: Yup.array(),
  client_location: Yup.string(),
  pickup_time: Yup.string(),
  total_paid: Yup.number().required("Total paid is required"),
  order_number: Yup.string().required("Regiondo order number is required"),
  client_messaged: Yup.boolean(),
  client_response_status: Yup.string().required("Status is required"),
  cancelled: Yup.boolean(),
  planned: Yup.boolean(),
  notes: Yup.string(),
  group: Yup.number(),
  updated_at: Yup.array().required("Author is required"),
  pickup_location: Yup.object().shape({
    latitude: Yup.string().when("name", {
      is: (val) => !!val,
      then: Yup.string().required("Pickup Latitude is required"),
      otherwise: Yup.string(),
    }),
    longitude: Yup.string().when("name", {
      is: (val) => !!val,
      then: Yup.string().required("Pickup Longitude is required"),
      otherwise: Yup.string(),
    }),
    google_maps_url: Yup.string().when("name", {
      is: (val) => !!val,
      then: Yup.string().required("Pickup Maps URL is required"),
      otherwise: Yup.string(),
    }),
    instructions: Yup.string().when("name", {
      is: (val) => !!val,
      then: Yup.string().required("Pickup instructions are required"),
      otherwise: Yup.string(),
    }),
    address: Yup.string(),
    img_url: Yup.string(),
    name: Yup.string(),
  }),
})

// const formikValidationSchema = (values) => {
//   //im using custom validation for complicated conditions,because yup is not able to capture
//   //the most recent values of inputs while typing,pickup_location_name is updated live while typing,
//   //yup is capturing the previous value of pickup_location_name,so validation fails

//   //YUP IMPLEMENTATION WHICH IS NOT WORKING
//   // pickup_location_lat: Yup.number().when("pickup_location_name", {
//   //   is: (val) => val !== "" && val !== null && val !== undefined,
//   //   then: Yup.number().required(),
//   //   otherwise: Yup.number().nullable(),
//   // }),
//   console.log("values", values);
//   const errors = {};

//   const latLongAreEmpty =
//     !values.pickup_location_lat && !values.pickup_location_lng;

//   if (values.pickup_location_name && latLongAreEmpty) {
//     errors.pickup_location_lat =
//       "Pickup location is not validated,please search the location again";
//     errors.pickup_location_lng =
//       "Pickup location is not validated,please search the location again";
//   }

//   return errors;
// };

//---------------------------------------------------------------

const recursivelyExtractErrorMessages = (errors) => {
  //error messages could be nested, so we need to recursively extract them
  let messages = []
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(errors)) {
    if (typeof value === "object") {
      messages = messages.concat(recursivelyExtractErrorMessages(value))
    } else {
      messages.push(`${value}`)
    }
  }
  ////dont return duplicate messages
  const messagesUnique = [...new Set(messages)]
  return messagesUnique
}

//-------------------------------------------------------------------------

const BookingForm = ({ id, refetch, closeForm }) => {
  const { user } = useAxios()
  const {
    data: bookingData = getDefaultBookingData(user),
    isFetching: isFetchingBooking,
    refetch: refetchBooking,
  } = useGetBookingById({
    id,
    enabled: !!id,
  })

  //--------------------------------------------------------------------------------
  const userIsAdmin = user.isAdmin
  const userIsPermittedToUpdateBookings =
    user.permissions.updateBookings || userIsAdmin

  //--------------------------------------------------------------------------------
  const location = useLocation()
  const useFormSubmit = useRef()
  //---------------------------------------------------

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement

      if (
        (event.key === "Enter" || event.keyCode === 13) &&
        useFormSubmit.current &&
        !activeElement.classList.contains("exclude-from-submit-to-enter")
      ) {
        if (userIsPermittedToUpdateBookings) {
          event.preventDefault()
          useFormSubmit.current()
        } else {
          alert("You don't have permission to update bookings")
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [userIsPermittedToUpdateBookings])

  //---------------------------------------------------

  const { isFetching: isFetchingEntities } = useFetchEntities()
  const { mutateAsync: addNewBooking, isLoading: isAddingNewBooking } =
    useAddNewBooking()
  const { mutateAsync: cloneBooking, isLoading: isCloningBooking } =
    useDuplicateBooking()
  const { mutateAsync: updateBooking, isLoading: isUpdatingBooking } =
    useUpdateBooking()
  const { mutateAsync: deleteBooking, isLoading: isDeletingBooking } =
    useDeleteBooking()

  const isLoading =
    isFetchingBooking ||
    isAddingNewBooking ||
    isUpdatingBooking ||
    isDeletingBooking

  const handleAddNewBooking = useCallback(
    async (values) => {
      try {
        await addNewBooking(values)
        if (refetch) {
          refetch()
        }

        closeForm()
      } catch (err) {
        closeForm()
        if (err?.response?.data) {
          alert(err.response.data)
        } else {
          alert(err.toString())
        }
        console.log(err)
      }
    },
    [closeForm, refetch, addNewBooking]
  )

  const handleUpdateBooking = useCallback(
    async (id, values) => {
      try {
        await updateBooking({
          id,
          payload: {
            ...values,
            updated_at: [
              ...values.updated_at,
              {
                author: user.name,
                date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
              },
            ],
          },
        })
        if (refetch) {
          refetch()
        }

        closeForm()
      } catch (err) {
        closeForm()
        if (err?.response?.data) {
          alert(err.response.data)
        } else {
          alert(err.toString())
        }
        console.log(err)
      }
    },
    [closeForm, refetch, updateBooking, user.name]
  )

  const handleDeleteBooking = useCallback(
    async (id) => {
      try {
        await deleteBooking(id)
        if (refetch) {
          refetch()
        }

        closeForm()
      } catch (err) {
        closeForm()
        if (err?.response?.data) {
          alert(err.response.data)
        } else {
          alert(err.toString())
        }
        console.log(err)
      }
    },
    [closeForm, refetch, deleteBooking]
  )

  //------------------------------------------------------------------------------------------

  return (
    <FormModal>
      <Container>
        {!isLoading && !isFetchingEntities && (
          <>
            <FormContainer>
              <Formik
                validateOnMount
                enableReinitialize
                initialValues={{
                  ...bookingData,
                  pickup_location: {
                    name: bookingData?.pickup_location?.name || "",
                    latitude: bookingData?.pickup_location?.latitude || "",
                    longitude: bookingData?.pickup_location?.longitude || "",
                    address: bookingData?.pickup_location?.address || "",
                    google_maps_url:
                      bookingData?.pickup_location?.google_maps_url || "",
                    instructions:
                      bookingData?.pickup_location?.instructions || "",
                    img_url: bookingData?.pickup_location?.img_url || "",
                  },
                  updated_at: bookingData?._id
                    ? [...bookingData.updated_at]
                    : [
                        {
                          author: user.name,
                          date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                        },
                      ],
                }}
                //validate={formikValidationSchema}
                validationSchema={yupValidationSchema}
                onSubmit={(values, errors) => {
                  values?._id
                    ? handleUpdateBooking(values._id, values)
                    : handleAddNewBooking({
                        ..._.omit(values, "_id"),
                      })
                }}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  initialValues,
                  resetForm,
                  dirty,
                }) => {
                  const custom_handleSubmit = () => {
                    if (!dirty) {
                      alert("No changes detected")
                      return
                    }
                    const itHasErrors = Object.keys(errors).length > 0
                    console.log(itHasErrors)
                    if (itHasErrors) {
                      const messages = recursivelyExtractErrorMessages(errors)
                      alert(messages.join("\n"))
                    } else {
                      handleSubmit()
                    }
                  }
                  useFormSubmit.current = custom_handleSubmit
                  return (
                    <>
                      {!isLoading && !isFetchingEntities && (
                        <>
                          <Form>
                            <TopContainer>
                              {bookingData?._id && (
                                <Tooltip title="Clone booking">
                                  <IconButton
                                    size="medium"
                                    onClick={async () => {
                                      try {
                                        if (window.confirm("Clone booking?")) {
                                          const response = await cloneBooking({
                                            user_id: user._id,
                                            booking_id: bookingData._id,
                                          })
                                          //@ts-ignore
                                          alert(response?.data)
                                          refetch()
                                          closeForm()
                                        }
                                      } catch (error) {
                                        alert(error?.toString())
                                      }
                                    }}
                                  >
                                    {isCloningBooking ? (
                                      <RotatingLines
                                        height="12"
                                        width="12"
                                        strokeColor="white"
                                      />
                                    ) : (
                                      <ContentCopyIcon
                                        fontSize="inherit"
                                        color="white"
                                        sx={{
                                          color: "white",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                              <CloseIcon
                                onClick={closeForm}
                                fontSize="large"
                                sx={{
                                  color: "white",
                                  cursor: "pointer",
                                  backgroundColor: "rgb(148 71 71)",
                                  borderBottomLeftRadius: "5px",
                                }}
                              />
                            </TopContainer>
                            {values._id && <TopButtonsSection />}
                            <BookingInfoSection />
                            <ClientInfoSection />
                            <ClientLocationSection />
                            <PickupLocationSection />

                            {values._id && (
                              <BookingHistory
                                bookingHistory={values.updated_at}
                              />
                            )}
                            {values._id && (
                              <BookingPortalLogs bookingRef={values.ref} />
                            )}
                          </Form>

                          <ButtonsContainer>
                            {values._id &&
                              location.pathname === "/bookings_manager" && (
                                <Button
                                  onClick={() => {
                                    if (!userIsPermittedToUpdateBookings) {
                                      alert(
                                        "You don't have permission to update bookings"
                                      )
                                      return
                                    }
                                    if (
                                      window.confirm(
                                        `Are you sure you want to delete this booking?`
                                      )
                                    ) {
                                      handleDeleteBooking(values._id)
                                    }
                                  }}
                                  variant="contained"
                                  color="error"
                                  sx={{
                                    flex: 1,
                                    backgroundColor: "indianred",
                                  }}
                                >
                                  Delete
                                </Button>
                              )}
                            <Button
                              onClick={refetchBooking}
                              variant="contained"
                              color="info"
                              sx={{ flex: 1 }}
                            >
                              Reset
                            </Button>
                            <Button
                              onClick={() => {
                                if (userIsPermittedToUpdateBookings) {
                                  custom_handleSubmit()
                                } else {
                                  alert(
                                    "You don't have permission to update bookings"
                                  )
                                }
                              }}
                              variant="contained"
                              color="success"
                              sx={{
                                flex: 3,
                              }}
                            >
                              {values._id ? "Update" : "Submit"}
                            </Button>
                          </ButtonsContainer>
                        </>
                      )}

                      {!isLoading && !isFetchingEntities && !bookingData && (
                        <span>ERROR GETTING BOOKING DATA</span>
                      )}
                    </>
                  )
                }}
              </Formik>
            </FormContainer>
          </>
        )}
        <SpinnerContainer>
          <BarLoader
            color="#c1c2c0"
            loading={isLoading || isFetchingEntities}
          />
        </SpinnerContainer>
      </Container>
    </FormModal>
  )
}
export default BookingForm;
