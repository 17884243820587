import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mdi/react";
import { mdiClose, mdiCheck } from "@mdi/js";

//---------------------------------------------------------------------------

const CustomMaterialTextInput = ({
  label,
  name,
  value,
  error,
  handleChange,
}: {
  label: string;
  name: string;
  value: string;
  error: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <TextField
      style={{ flex: 1, minWidth: 200 }}
      name={name}
      label={label}
      margin="dense"
      type="text"
      color="success"
      value={value}
      onChange={handleChange}
      multiline
      maxRows={Infinity}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            style={{
              paddingLeft: "10px",
            }}
          >
            {error ? (
              <Icon path={mdiClose} size={0.8} color="indianred" />
            ) : (
              <Icon path={mdiCheck} size={0.8} color="green" />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomMaterialTextInput;
