import styled from "styled-components";
import Header from "../components/header/Header";
import DeveloperPageContent from "../components/developer_page/DeveloperPageContent";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
`;

//----------------------------------------------------------------------------------

const DeveloperPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header
        text={"Getaways Suite"}
        redirectFn={() => {
          navigate("/home");
        }}
      />
      <ContentContainer>
        <DeveloperPageContent />
      </ContentContainer>
    </Container>
  );
};

export default DeveloperPage;
