import { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "styled-components"
import moment from "moment"
import BarLoader from "react-spinners/BarLoader"
import _ from "lodash"
import {
  useFetchEntities,
  useAddTask,
  useEditTask,
  useDeleteTask,
  useTriggerRefreshTasksInTourGroup,
} from "../../../reactQueryHooks"
import CustomDropdown from "../settings/content/users/CustomDropdown"
import CustomSectionedMultiSelectList from "../day_planner_tour_groups/content/CustomSectionedMultiSelectList"
import PickupsList from "./PickupsList"
import { useAxios } from "../../../axiosProvider"

//----------------------------------------------------------------------

const yupValidationSchema = Yup.object().shape({
  product: Yup.string().required("Product is required"),
  date: Yup.date().required("Date is required"),
  assignees: Yup.array().of(
    Yup.object().shape({
      user_id: Yup.string(),
      role_id: Yup.string(),
    })
  ),
  vehicle_id: Yup.string().nullable(),
  pickups: Yup.array().of(
    Yup.object().shape({
      meeting_point: Yup.string(),
      time: Yup.string(),
      details: Yup.string(),
      lat: Yup.number().typeError("Please validate all meeting points"),
      lon: Yup.number().typeError("Please validate all meeting points"),
      guests: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          count: Yup.number(),
        })
      ),
    })
  ),
  details: Yup.string().nullable(),
  author_id: Yup.string().required("Author is required"),
  silent: Yup.boolean(),
})

const formikValidationSchema = (values) => {
  const errors = {}

  const latLongAreEmpty =
    !values.pickup_location_lat && !values.pickup_location_lng

  if (values.pickup_location_name && latLongAreEmpty) {
    errors.pickup_location_lat =
      "Pickup location is not validated,please search the location again"
    errors.pickup_location_lng =
      "Pickup location is not validated,please search the location again"
  }

  return errors
}

//--------------------------------------------------------------------------------------------

const Wrapper = styled.div`
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  //backdrop-filter: blur(5px);
  padding: 15px;
  max-width: 850px;
  * {
    font-size: clamp(11px, 1vw, 13px);
  }
  overflow: hidden;
  max-height: 100%;
`

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  i {
    font-size: 20px;
    cursor: pointer;
    color: white;
    &:hover {
      color: indianred;
    }
  }
`

const SpinnerContainer = styled.div`
  align-self: center;
`

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
`

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* background-color: red; */
  padding: 0 5px 0 0;
  overflow-y: auto;
`

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  flex-wrap: wrap;
`

const SectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .form-header {
    font-weight: bold;
    font-size: 1.1em;
    color: ${(props) => props.theme.colors.lightblue};
  }
`

const FieldContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 5px;

  min-height: 45px;
  padding: 0 5px;
  color: black;
  &:hover {
    background-color: white;
  }

  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  input {
    flex: 1;
    height: 100%;
    text-align: center;
    align-self: center;
    // background-color: transparent;
    font-family: "Roboto", sans-serif;
    cursor: default;
    //box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  select {
    flex: 1;
    //max-width: 150px;
    border: none;
    background-color: transparent;
    height: 100%;
    width: 150px;
    font-weight: 500;
    text-align: center;
    &:focus {
      outline: none;
    }
    text-overflow: ellipsis;

    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #91c9ff;
    }
  }
  .icon {
    font-size: 14px;
    color: #636363;
  }

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const NotesTextarea = styled.textarea`
  flex: 1;
  height: 100%;
  text-align: "start";
  align-self: center;
  //padding-top: 10px;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  cursor: default;
  resize: none;
  border: none;
  &:focus {
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: dodgerblue;
    border-radius: 50px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //background-color: white;
  //padding: 5px 0 0 0;
  gap: 5px;
  width: 100%;
  min-height: 50px;
  button {
    font-size: 13px;
    font-weight: 600;
  }
  //stick to bottom
  /* position: sticky;
  bottom: 5px;
  flex: 0; */
`

const ResetButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blue};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`
const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.colors.red};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`

const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`

const NotificationCheckButton = styled.button`
  background-color: #a0a0a0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  //input checkbox styles
  input {
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    color: dodgerblue;
    accent-color: ${({ theme }) => theme.colors.blue};
  }
`

//---------------------------------------------------------------

const recursivelyExtractErrorMessages = (errors) => {
  //error messages could be nested, so we need to recursively extract them
  let messages = []
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(errors)) {
    if (typeof value === "object") {
      messages = messages.concat(recursivelyExtractErrorMessages(value))
    } else {
      messages.push(`${value}`)
    }
  }
  ////dont return duplicate messages
  const messagesUnique = [...new Set(messages)]
  return messagesUnique
}

//-------------------------------------------------------------------------

const ScheduleTaskForm = ({
  isNew,
  data,
  refetch,
  closeForm,
  instanceId,
  tourGroupId,
}) => {
  const { mutateAsync: triggerRefreshTasksInTourGroup } =
    useTriggerRefreshTasksInTourGroup()
  const [isLoading, setIsLoading] = useState(false)
  const [isSortingPickups, setIsSortingPickups] = useState(false)
  const { user } = useAxios()
  const { data: entities, isFetching: isFetchingEntities } = useFetchEntities()
  const authors = entities.data.staff.filter(
    (user) => user.isModerator || user.isAdmin
  )
  const { mutateAsync: addTask } = useAddTask()
  const { mutateAsync: editTask } = useEditTask()
  const { mutateAsync: deleteTask } = useDeleteTask()

  //-----------------------------------------

  const handleAddNewTask = async (values) => {
    if (values.vehicle_id === null) {
      delete values.vehicle_id
    }

    if (values.details === null) {
      delete values.details
    }

    try {
      await addTask(values)
      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      })
      refetch()
      setIsLoading(false)
      closeForm()
    } catch (err) {
      console.log(err)
      closeForm()
      if (err.response.data) {
        alert(err.response.data)
      } else {
        alert(err.toString())
      }
    }
  }

  const handleEditTask = async (id, values) => {
    if (values.vehicle_id === null) {
      delete values.vehicle_id
    }

    if (values.details === null) {
      delete values.details
    }

    try {
      setIsLoading(true)
      await editTask({
        id,
        payload: values,
      })
      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      })
      refetch()
      closeForm()
    } catch (err) {
      console.log(err)
      closeForm()
      if (err.response.data) {
        alert(err.response.data)
      } else {
        alert(err.toString())
      }
    }
  }

  const handleDeleteTask = async (id) => {
    try {
      await deleteTask(id)
      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      })
      refetch()
      closeForm()
    } catch (err) {
      console.log(err)
      closeForm()
      if (err.response.data) {
        alert(err.response.data)
      } else {
        alert(err.toString())
      }
    }
  }

  //---------------------------------------------------------

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Container>
        {!isLoading && !isFetchingEntities && (
          <>
            <HeaderContainer>
              <i
                className="fas fa-times"
                onClick={() => {
                  closeForm()
                }}
              ></i>
            </HeaderContainer>
            <FormContainer>
              <Formik
                validateOnMount
                //enableReinitialize
                initialValues={{
                  date: data.date,
                  product: data.product,
                  option_id: data.option_id,
                  assignees: data ? (data.assignees ? data.assignees : []) : [],
                  vehicle_id: data
                    ? data.vehicle_id
                      ? data.vehicle_id
                      : null
                    : null,
                  pickups: data ? (data.pickups ? data.pickups : []) : [],
                  details: data ? (data.details ? data.details : null) : null,
                  silent: true,
                  author_id: data?.author_id || user._id,
                  tour_group_id: data.tour_group_id,
                }}
                validate={formikValidationSchema}
                validationSchema={yupValidationSchema}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                  if (isNew) {
                    if (window.confirm("Submit new task?")) {
                      handleAddNewTask({ ...values, silent: false })
                    }
                  } else {
                    if (window.confirm("Update this task?")) {
                      handleEditTask(data.id, values)
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                  isSubmitting,
                  validateForm,
                }) => {
                  //--- BUILD DATA FOR CustomSectionedMultiSelectList ------------------------------

                  const product = entities.data.products.find(
                    (product) => product._id === values.product
                  )
                  const option = product.options.find(
                    (option) => option.bokun_code === data.option_id
                  )
                  const crewData = product.crewRoles.map((roleId) => {
                    const role = entities.data.roles.find(
                      (r) => r._id === roleId
                    )

                    const roleData = {
                      section: {
                        label: role.title,
                        value: roleId,
                      },
                      data: entities.data.staff
                        .filter((user) =>
                          user.roles.find((r) => r._id === roleId)
                        )
                        .map((user) => {
                          return {
                            label: user.name,
                            value: user._id,
                          }
                        }),
                    }
                    return roleData
                  })

                  //--- BUILD SELECTED DATA FOR CustomSectionedMultiSelectList ------------------------------

                  const groupedAssigneesByRole = _.groupBy(
                    values.assignees,
                    "role_id"
                  )
                  const selectedCrewData = Object.keys(
                    groupedAssigneesByRole
                  ).map((role) => {
                    const roleData = entities.data.roles.find(
                      (r) => r._id === role
                    )
                    const section = {
                      label: roleData.title,
                      value: roleData._id,
                    }
                    const data = groupedAssigneesByRole[role].map((user) => {
                      const userData = entities.data.staff.find(
                        (u) => u._id === user.user_id
                      )
                      return {
                        label: userData.name,
                        value: userData._id,
                      }
                    })
                    return {
                      section,
                      data,
                    }
                  })

                  //-------------------------
                  //selected data count
                  const selectedDataCount = selectedCrewData.reduce(
                    (acc, section) => {
                      return acc + section.data.length
                    },
                    0
                  )

                  //---------------------------

                  const totalPickups = values.pickups.length
                  const shouldShowPickupsList = totalPickups > 0
                  const shouldShowSortingButton = totalPickups > 1
                  const totalGuests = values.pickups.reduce((acc, pickup) => {
                    return (
                      acc +
                      pickup.guests.reduce((acc, guest) => {
                        return acc + Number(guest.count)
                      }, 0)
                    )
                  }, 0)

                  return (
                    <>
                      {!isLoading && !isFetchingEntities && (
                        <>
                          <Form>
                            <SectionWrapper>
                              <SectionContainer>
                                <span className="form-header">Task info</span>
                              </SectionContainer>
                              <SectionContainer>
                                <FieldWrapper>
                                  <FieldContainer
                                    title="Product"
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    {product.title}
                                  </FieldContainer>
                                  <FieldContainer
                                    title="Product option"
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    {option.title}
                                  </FieldContainer>
                                  <FieldContainer
                                    style={{
                                      flex: 1,
                                    }}
                                    title="Event date"
                                  >
                                    {moment(values.date).format(
                                      "ddd, DD MMM YYYY"
                                    )}
                                  </FieldContainer>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <FieldContainer
                                    title="Author"
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    <select
                                      onChange={(e) => {
                                        if (e.target.value === "reset") {
                                          setFieldValue("author_id", "")
                                        } else {
                                          setFieldValue(
                                            "author_id",
                                            e.target.value
                                          )
                                        }
                                      }}
                                      value={values.author_id}
                                    >
                                      <option value={"reset"}>
                                        Select task author
                                      </option>
                                      {authors.map((author, index) => (
                                        <option
                                          value={author._id}
                                          key={author._id}
                                        >
                                          {author.name}
                                        </option>
                                      ))}
                                    </select>
                                  </FieldContainer>
                                  <CustomDropdown
                                    isShown={(isShown) => {
                                      // setIsDropdownOpen(isShown);
                                    }}
                                    dropdownContent={
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          height: "300px",
                                          minWidth: "100px",
                                          width: "100%",
                                          padding: "10px",
                                          backgroundColor: "white",
                                          borderRadius: "5px",
                                          boxShadow:
                                            "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                                          gap: "10px",
                                        }}
                                      >
                                        <CustomSectionedMultiSelectList
                                          data={crewData}
                                          selectedData={selectedCrewData}
                                          onSelect={async (
                                            role_id,
                                            user_id
                                          ) => {
                                            const userIncluded =
                                              values.assignees.find(
                                                (assignee) =>
                                                  assignee.user_id === user_id
                                              )
                                            if (userIncluded) {
                                              const filteredAssignees =
                                                values.assignees.filter(
                                                  (assignee) =>
                                                    assignee.user_id !== user_id
                                                )
                                              setFieldValue(
                                                "assignees",
                                                filteredAssignees
                                              )
                                            } else {
                                              setFieldValue("assignees", [
                                                ...values.assignees,
                                                {
                                                  user_id,
                                                  role_id,
                                                },
                                              ])
                                            }
                                          }}
                                        />
                                      </div>
                                    }
                                  >
                                    <FieldContainer
                                      style={{
                                        flex: 1,
                                        userSelect: "none",
                                      }}
                                    >
                                      {`Select crew (${selectedDataCount} selected)`}
                                    </FieldContainer>
                                  </CustomDropdown>

                                  <FieldContainer
                                    title="Vehicle"
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    <select
                                      onChange={(e) => {
                                        if (e.target.value === "reset") {
                                          setFieldValue("vehicle_id", null)
                                        } else {
                                          setFieldValue(
                                            "vehicle_id",
                                            e.target.value
                                          )
                                        }
                                      }}
                                      value={values.vehicle_id || "reset"}
                                    >
                                      <option value="reset">
                                        Select a vehicle
                                      </option>
                                      {entities.data.vehicles.map(
                                        (vehicle, index) => (
                                          <option
                                            value={vehicle._id}
                                            key={index}
                                          >
                                            {vehicle.plate}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </FieldContainer>
                                </FieldWrapper>
                                <FieldWrapper>
                                  <FieldContainer
                                    style={{ flex: 1, height: "60px" }}
                                    title="Details"
                                  >
                                    <NotesTextarea
                                      type="text"
                                      placeholder="Details ✎"
                                      name="details"
                                      onChange={handleChange}
                                      value={values.details}
                                      autoComplete="off"
                                      spellCheck="false"
                                    />
                                  </FieldContainer>
                                </FieldWrapper>
                              </SectionContainer>
                            </SectionWrapper>

                            <SectionWrapper>
                              <SectionContainer>
                                <span className="form-header">Pickups</span>
                              </SectionContainer>
                              <SectionContainer
                                style={{
                                  padding: "10px",
                                  borderRadius: "5px",
                                  backgroundColor: "rgba(255,255,255,0.4)",
                                  gap: "10px",
                                }}
                              >
                                <FieldWrapper>
                                  <FieldContainer
                                    style={{
                                      backgroundColor: "transparent",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "white",
                                      textShadow: "0px 0px 5px rgba(0,0,0,0.5)",
                                    }}
                                  >
                                    {`Pickups: ${totalPickups}  Pax: ${totalGuests}`}
                                  </FieldContainer>
                                  <FieldContainer
                                    style={{
                                      maxWidth: "150px",
                                      gap: "10px",
                                      userSelect: "none",
                                      cursor: "pointer",
                                    }}
                                    title="Add pickup"
                                    onClick={() => {
                                      setFieldValue("pickups", [
                                        ...values.pickups,
                                        {
                                          meeting_point: "",
                                          time: "",
                                          details: "",
                                          lat: null,
                                          lon: null,
                                          guests: [
                                            {
                                              name: "",
                                              count: "",
                                            },
                                          ],
                                        },
                                      ])
                                      setIsSortingPickups(false)
                                    }}
                                  >
                                    <span>Add pickup</span>
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        color: "darkgreen",
                                      }}
                                      className="fas fa-plus"
                                    ></i>
                                  </FieldContainer>
                                  {shouldShowSortingButton && (
                                    <FieldContainer
                                      style={{
                                        maxWidth: "100px",
                                        userSelect: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setIsSortingPickups(!isSortingPickups)
                                      }
                                    >
                                      <i
                                        style={{
                                          fontSize: "20px",
                                        }}
                                        className="fa-solid fa-sort"
                                      ></i>
                                    </FieldContainer>
                                  )}
                                </FieldWrapper>
                                {shouldShowPickupsList && (
                                  <PickupsList
                                    meetingPoints={entities.data.meetingPoints}
                                    isSortingPickups={isSortingPickups}
                                    setIsSortingPickups={setIsSortingPickups}
                                  />
                                )}
                              </SectionContainer>
                            </SectionWrapper>
                          </Form>
                          <ButtonsContainer>
                            {!isNew && (
                              <DeleteButton
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `Are you sure you want to delete this task?`
                                    )
                                  ) {
                                    handleDeleteTask(data.id)
                                  }
                                }}
                              >
                                Delete
                              </DeleteButton>
                            )}
                            <ResetButton type="button" onClick={resetForm}>
                              Reset form
                            </ResetButton>
                            {!isNew && (
                              <NotificationCheckButton
                                onClick={() => {
                                  setFieldValue("silent", !values.silent)
                                }}
                              >
                                <span>Notifications</span>
                                <i
                                  style={{
                                    fontSize: "16px",
                                    color: "white",
                                  }}
                                  className={`fas fa-bell${
                                    values.silent ? "-slash" : ""
                                  }`}
                                ></i>
                              </NotificationCheckButton>
                            )}
                          </ButtonsContainer>
                          <ButtonsContainer>
                            <SubmitButton
                              type="button"
                              onClick={() => {
                                const itHasErrors =
                                  Object.keys(errors).length > 0
                                if (itHasErrors) {
                                  const messages =
                                    recursivelyExtractErrorMessages(errors)
                                  alert(messages.join(", "))

                                  console.log("HAS ERRORS", values)
                                } else {
                                  handleSubmit()
                                }
                              }}
                            >
                              {isNew ? "Submit" : "Update"}
                            </SubmitButton>
                          </ButtonsContainer>
                        </>
                      )}
                    </>
                  )
                }}
              </Formik>
            </FormContainer>
          </>
        )}
        <SpinnerContainer>
          <BarLoader
            color="#c1c2c0"
            loading={isLoading || isFetchingEntities}
          />
        </SpinnerContainer>
      </Container>
    </Wrapper>
  )
}

export default ScheduleTaskForm
