import styled from "styled-components";
import Header from "../components/header/Header";
import HomeContent from "../components/home/HomeContent";
import { useNavigate } from "react-router-dom";

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
`;

const Home = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header
        text={"Getaways Suite"}
        redirectFn={() => {
          navigate("/home");
        }}
      />
      <ContentContainer>
        <HomeContent />
      </ContentContainer>
    </Container>
  );
};

export default Home;
