import { useContext } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useFetchEntities } from "../../../reactQueryHooks";
import { AppContext } from "../../../App";
//---------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  border-color: rgb(54 85 116);
  width: 100%;
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
  //if is day planner route,make the date span underlined on hover
  .date-span {
    ${({ isDayPlannerRoute }) =>
      isDayPlannerRoute &&
      `
      &:hover {
        text-decoration: underline;
        color: dodgerblue;
      }
    `}
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

//---------------------------------------------------------------------------

const Booking = ({ booking }) => {
  const location = useLocation();
  const isDayPlannerRoute = location.pathname === "/day_planner";
  const { setDayPlannerDate, setBookingFormBookingId, setIsBookingFormOpen } =
    useContext(AppContext);

  const date = moment(booking.date).format("DD-MM-YYYY ddd");
  const ticketsString = Object.keys(booking.tickets)
    .map((key) => `${booking.tickets[key]} x ${key}`)
    .join(", ");

  const { data: entities } = useFetchEntities();
  const product = entities?.data?.products.find(
    (entity) => entity._id === booking.product_id
  );


  return (
    <Container
      isDayPlannerRoute={isDayPlannerRoute}
      onClick={() => {
        setBookingFormBookingId(booking._id);
        setIsBookingFormOpen(true);
      }}
    >
      <Row>
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {product.title}
        </span>
        {booking.cancelled && (
          <span
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              color: "indianred",
            }}
          >
            CANCELLED
          </span>
        )}
      </Row>
      <Row>
        <span>{booking.ref}</span>
        <span
          className="date-span"
          onClick={(event) => {
            event.stopPropagation();
            if (isDayPlannerRoute) {
              setDayPlannerDate(moment(booking.date).format("YYYY-MM-DD"));
            }
          }}
        >
          {date}
        </span>
      </Row>
      <Row>
        <span
          style={{
            color: "rgb(27 113 199)",
          }}
        >
          {booking.client_name}
        </span>
        <span>{ticketsString}</span>
      </Row>
    </Container>
  );
};

export default Booking;
