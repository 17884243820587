import styled from "styled-components";
const Container = styled.div`
  display: flex;
  min-height: 45px;
  /* position: sticky;
  top: 0; */
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);
  z-index: 900;
`;

const SelectionBar = ({ children }) => {
  return <Container>{children}</Container>;
};

export default SelectionBar;
