import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//-------------------------------------------

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //gap: 5px;
  overflow-y: auto;
  padding-right: 5px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    /* border-radius: 50px; */
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  :hover {
    div {
      opacity: 0.6;
    }
  }
  color: ${(props) => (props.isSelected ? "#1b81e5" : "black")};
  padding: 4px 8px 4px 6px;
  //font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};
  background-color: ${(props) =>
    props.isSelectedByKey ? "whitesmoke" : "white"};
  border-radius: 5px;
`;

//-------------------------------------------

const CustomSelect = ({ data, selectedData, onSelect }) => {
  /*
DATA EXAMPLE:
[
    {
        label: "Monday",
        value: "monday"
    },
    {   
        label: "Tuesday",   
        value: "tuesday"
    },
    
]
*/

  const [itemSelectedByKeyIndex, setItemSelectedByKeyIndex] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setItemSelectedByKeyIndex((prevIndex) =>
          //dont go lower than 0
          prevIndex > 0 ? prevIndex - 1 : 0
        );
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setItemSelectedByKeyIndex((prevIndex) =>
          //dont go past the last item index
          prevIndex < data.length - 1 ? prevIndex + 1 : data.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        const selectedItem = data[itemSelectedByKeyIndex];
        if (selectedItem) {
          onSelect(selectedItem);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [itemSelectedByKeyIndex, data, onSelect]);

  useEffect(() => {
    const selectedRow = listRef?.current?.children[itemSelectedByKeyIndex];
    if (selectedRow) {
      const listRect = listRef.current.getBoundingClientRect();
      const rowRect = selectedRow.getBoundingClientRect();

      // Check if the selected item is already visible
      if (rowRect.top >= listRect.top && rowRect.bottom <= listRect.bottom) {
        return; // No need to scroll if already visible
      }

      selectedRow.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [itemSelectedByKeyIndex]);

  return (
    <Container ref={listRef}>
      {data.map((item, index) => {
        const isSelected = selectedData.value === item.value;
        const isSelectedByKey = itemSelectedByKeyIndex === index;

        return (
          <RowContainer
            isSelected={isSelected}
            isSelectedByKey={isSelectedByKey}
            key={index}
            onClick={() => onSelect(item)}
          >
            <div
              style={{
                fontSize: "12px",
              }}
            >
              {item.label}
            </div>
            <i
              style={{
                color: "darkgreen",
                opacity: isSelected ? 1 : 0,
              }}
              className="fas fa-check"
            ></i>
          </RowContainer>
        );
      })}
    </Container>
  );
};

export default CustomSelect;
