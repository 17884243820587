import { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import ReactDOMServer from "react-dom/server";
// import BarLoader from "react-spinners/BarLoader";
//import mapboxgl, { LngLat } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AppContext } from "../../../App";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";

//imported in index.html
// @ts-ignore
mapboxgl.accessToken =
  "pk.eyJ1Ijoibm9zcGlwaSIsImEiOiJjbGVoOWc2MWMwYjVzM29xZjJkNjlnZzU3In0.ygeBpLtHj3dWNMii9EL1qw";

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 90%;
  height: 90%;
  max-width: 650px;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  height: 50px;

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const MapContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

//---------------------------------------------------------

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 4px 0px 4px;
  background-color: white;
  border-radius: 5px;

  span {
    padding: 0;
    margin: 0;
  }
  cursor: default;
`;

const PopupItem = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  i {
    font-size: 12px;
  }
`;

//---------------------------------------------------------

const FindPickupLocationModal = ({
  close,
  meetingPoints,
}: {
  close: () => void;
  meetingPoints: [];
}) => {
  const {
    values,
    setFieldValue,
  }: {
    values: any;
    setFieldValue: any;
  } = useFormikContext();
  const [isPickupLocationFindLoading, setIsPickupLocationFindLoading] =
    useState(false);

  const Popup = ({ name }: { name: string }) => {
    return (
      <PopupContainer>
        <PopupItem>
          {/* <i className="fa-solid fa-location-dot"></i> */}
          <span>{name}</span>
        </PopupItem>
      </PopupContainer>
    );
  };

  // const [map, setMap] = useState(null);
  // const [marker, setMarker] = useState(null);

  const mapContainer = useRef<HTMLDivElement>(null);
  const newMap = useRef<mapboxgl.Map | null>(null);
  //const [map, setMap] = useState<mapboxgl.Map>();
  const appContext = useContext(AppContext);
  const { googleInstance } = appContext;

  useEffect(() => {
    if (mapContainer.current) {
      // @ts-ignore
      newMap.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // Mapbox style
        center: [23.7275, 37.9838], // Initial center coordinates (Athens, Greece)
        zoom: 15, // Initial zoom level
      });
    }
  }, []);

  useEffect(() => {
    if (newMap.current) {
      for (let meetingPoint of meetingPoints) {
        const {
          name,
          latitude,
          longitude,
          google_maps_url,
          address,
          img_url,
          instructions,
        } = meetingPoint

        const popupToHTML = ReactDOMServer.renderToString(<Popup name={name} />)

        // Create a popup object for each marker
        // @ts-ignore
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
        popup.setHTML(popupToHTML)

        // @ts-ignore
        const marker = new mapboxgl.Marker()
          .setLngLat([longitude, latitude])
          .setPopup(popup)
          .addTo(newMap.current)

        //open popup immediately
        //marker.togglePopup();

        //add another listener when hovering the marker
        marker.getElement().addEventListener("mouseenter", () => {
          //open popup
          marker.togglePopup()
        })
        // mouseleave
        marker.getElement().addEventListener("mouseleave", () => {
          //close popup
          marker.togglePopup()
        })
        //on click
        marker.getElement().addEventListener("click", async () => {
          await setFieldValue("pickup_location.name", name)
          await setFieldValue("pickup_location.latitude", latitude)
          await setFieldValue("pickup_location.longitude", longitude)
          await setFieldValue(
            "pickup_location.google_maps_url",
            google_maps_url
          )
          await setFieldValue("pickup_location.address", address)
          await setFieldValue("pickup_location.img_url", img_url)
          await setFieldValue("pickup_location.instructions", instructions)
          close()
        })
      }
    }
  }, [meetingPoints, setFieldValue, close])

  const handleFindPickupLocation = async () => {
    try {
      if (!googleInstance) {
        alert("Google Maps API has trouble loading ! Please check logs")
        return
      }
      setIsPickupLocationFindLoading(true)
      const geocoder = new googleInstance.Geocoder()
      geocoder.geocode(
        { address: values.client_location },
        (results: any, status: any) => {
          if (status === "OK") {
            //const formattedAddress = results[0].formatted_address;
            const location = results[0].geometry.location
            const lat = location.lat()
            const lng = location.lng()
            const mapsUrl = `https://www.google.com/maps/place/${lat},${lng}`

            if (newMap.current) {
              newMap.current.flyTo({
                center: [lng, lat],
                essential: true,
                zoom: 16,
              })
            }

            const popupToHTML = ReactDOMServer.renderToString(
              <Popup name={values.client_location} />
            )
            //@ts-ignore
            const popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
            popup.setHTML(popupToHTML)
            //@ts-ignore
            const marker = new mapboxgl.Marker({
              color: "indianred",
            })
              .setLngLat([lng, lat])
              .setPopup(popup)

              //@ts-ignore
              .addTo(newMap.current)
              .togglePopup()

            marker.getElement().addEventListener("click", () => {
              //prevent popup from closing if user clicks on it by calling togglePopup again
              marker.togglePopup()
            })

            marker.getElement().addEventListener("click", async () => {
              await setFieldValue(
                "pickup_location.name",
                values.client_location
              )
              await setFieldValue("pickup_location.latitude", lat)
              await setFieldValue("pickup_location.longitude", lng)
              await setFieldValue("pickup_location.google_maps_url", mapsUrl)
              close()
            })

            setIsPickupLocationFindLoading(false)
          } else {
            setIsPickupLocationFindLoading(false)
            alert(status)
          }
        }
      )
    } catch (error: any) {
      setIsPickupLocationFindLoading(false)
      console.log(error)
      alert(error.toString())
    }
  }

  return (
    <Wrapper>
      <Modal>
        <CloseContainer>
          <CloseButton onClick={close}>
            <i className="fas fa-times"></i>
          </CloseButton>
        </CloseContainer>
        <InputWrapper>
          <InputRow>
            <span
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor: "whitesmoke",
                borderRadius: "5px",
                padding: "0 8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {values.client_location}
              </span>
            </span>

            <Button
              title="Search Location"
              onClick={handleFindPickupLocation}
              variant="contained"
              style={{
                backgroundColor: "rgb(109 170 231)",
                minWidth: "100px",
              }}
            >
              {isPickupLocationFindLoading ? (
                <i
                  style={{
                    fontSize: "13px",
                  }}
                  className="fa-solid fa-circle-notch spinner icon"
                ></i>
              ) : (
                <span>SEARCH</span>
              )}
            </Button>
          </InputRow>
        </InputWrapper>
        <MapWrapper>
          <MapContainer ref={mapContainer} />
        </MapWrapper>
      </Modal>
    </Wrapper>
  );
};

export default FindPickupLocationModal;
