import { useQuery, useInfiniteQuery, useMutation } from "react-query";
import { useAxios } from "./axiosProvider"

//-----------------------------------------------------------------------------------

// const { mutateAsync: addNewServiceLogEntry, isLoading } =
//   useAddNewServiceLogEntry();

const useCustomQuery = (key: any, queryFn: any, options = {}) => {
  return useQuery(key, queryFn, {
    retry: false,
    ...options,
    onError: (error: any) => {
      const url = error.config.url
      console.log("ERROR FROM RQ USEINFINITEQUERY HOOK:", error, url)
    },
  })
}

const useCustomInfiniteQuery = (key: any, queryFn: any, options = {}) => {
  return useInfiniteQuery(key, queryFn, {
    onError: (error: any) => {
      const url = error.config.url
      console.log("ERROR FROM RQ USEINFINITEQUERY HOOK:", error, url)
    },
    retry: false,
    ...options,
  })
}
const useCustomMutation = (mutationFn: any, options = {}) => {
  return useMutation(mutationFn, {
    onError: (error: any) => {
      const url = error.config.url
      console.log("ERROR FROM RQ USEINFINITEQUERY HOOK:", error, url)
    },
    retry: false,
    ...options,
  })
}

//-----------------------------------------------------------------------------------

export const useFetchEntities = () => {
  const { axiosInstance, isAuthenticated } = useAxios()
  return useCustomQuery("ENTITIES", () => axiosInstance.get(`api/entities`), {
    staleTime: Infinity,
    enabled: isAuthenticated,
  })
}

export const useLoginJWTAuth = () => {
  const { axiosInstance, setUser, setToken, setIsAuthenticated } = useAxios()

  const loginJWTAuth = async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        `api/login/bookings_manager/loginjwt`,
        payload
      )
      const { data } = response
      const { token, user } = data
      setUser(user)
      setToken(token)
      setIsAuthenticated(true)
      return
    } catch (error) {
      throw error
    }
  }

  return { loginJWTAuth }
}

export const useGetUserOfficeDutyStatus = (id: any) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    "USER_OFFICE_DUTY_STATUS",
    () => axiosInstance.get(`api/users/office_duty/${id}`),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetAllOfficeDutyUsers = () => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    "ALL_OFFICE_DUTY_USERS",
    () =>
      axiosInstance.get(`api/users/office_duty_all/get_all_office_duty_users`),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  )
}

export const useUpdateUserOfficeDutyStatus = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({ id, isOnDuty }: { id: string; isOnDuty: boolean }) =>
      axiosInstance.post(`api/users/office_duty/${id}`, { isOnDuty })
  )
}

//-----------------------------------------------------------------------------------

export const useGetUnreadNotifications = (id: any) => {
  const { axiosInstance } = useAxios()

  return useCustomQuery(
    "UNREAD_NOTIFICATIONS",
    () => axiosInstance.get(`api/notifications/unread/${id}`),
    {
      //staleTime: Infinity,
    }
  )
}

export const useGetReadNotifications = (id: string) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    "READ_NOTIFICATIONS",
    ({ pageParam = 1 }) =>
      axiosInstance.get(`api/notifications/read/${pageParam}/${id}`),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },

      staleTime: Infinity,
    }
  )
}

//-----------------------------------------------------------------------------------

export const useFetchTourGroupById = (id: string) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TOUR_GROUP", id],
    async () => {
      const tourGroup = await axiosInstance.get(`api/tour_groups/${id}`)
      return tourGroup.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchTourGroupIdsBySpecificDate = (date: string) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TOUR_GROUP_IDS_BY_SPECIFIC_DATE", date],
    async () => {
      const tourGroups = await axiosInstance.get(`api/tour_groups/date/${date}`)
      return tourGroups.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchTourGroupsBySpecificDate = (date: string) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TOUR_GROUPS_BY_SPECIFIC_DATE", date],
    async () => {
      const tourGroups = await axiosInstance.get(
        `api/tour_groups/date/${date}/docs`
      )
      return tourGroups.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchTourGroupsByDate = (start: any, end: any) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TOUR_GROUPS", start, end],
    async () => {
      const tourGroups = await axiosInstance.get(
        `api/tour_groups/dates/${start}/${end}`
      )
      return tourGroups.data
    },
    {
      //staleTime 5 minutes
      //staleTime: 1000 * 60 * 5,
      //staleTime instant
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchAllTourGuideIdsWithAssignments = () => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    "TOUR_GUIDE_IDS_WITH_ASSIGNMENTS",
    async () => {
      const tourGuides = await axiosInstance.get(
        `api/tour_groups/tour_guides/tour_guides_with_assignments`
      )
      return tourGuides.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchTourGroupsByDateTourGuides = ({
  start_date,
  guide_ids,
  product_ids,
  include_cancelled,
  include_unassigned,
  only_unassigned,
  enabled,
}: {
  start_date: string
  product_ids: string[]
  guide_ids: string[]
  include_cancelled: boolean
  include_unassigned: boolean
  only_unassigned: boolean
  enabled: boolean
}) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    "TOUR_GROUPS_TOUR_GUIDES",
    ({ pageParam = 1 }) =>
      axiosInstance.post(`api/tour_groups/dates/tour_guides`, {
        page: pageParam,
        start_date,
        guide_ids,
        product_ids,
        include_cancelled,
        include_unassigned,
        only_unassigned,
      }),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },
      enabled: enabled,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useSortBookingsInTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.patch(`api/tour_groups/sort`, payload)
  )
}

export const useAddTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.patch(`api/tour_groups/add_tour_group`, payload)
  )
}

export const useTriggerRefreshFilesInTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    (payload: {
      instance_id: string //created with uuidv4 in order to be prevent refreshing the same component it was triggered from
      tour_group_id: string
    }) => axiosInstance.post(`api/tour_groups/refresh_files`, payload)
  )
}

export const useTriggerRefreshTasksInTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    (payload: {
      instance_id: string //created with uuidv4 in order to be prevent refreshing the same component it was triggered from
      tour_group_id: string
    }) => axiosInstance.post(`api/tour_groups/refresh_tasks`, payload)
  )
}

//-----------------------------------------------------------------------------------

export const useFetchTask = (task_id: string) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TASK", task_id],
    () => axiosInstance.get(`api/tasks/${task_id}`),
    { staleTime: 0, cacheTime: 0, refetchOnWindowFocus: false }
  )
}

export const useAddTask = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/tasks`, { ...payload })
  )
}

export const useEditTask = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: { id: string; payload: any }) =>
    axiosInstance.patch(`api/tasks/${id}`, { ...payload })
  )
}

export const useDeleteTask = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((task_id: any) =>
    axiosInstance.delete(`api/tasks/${task_id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useGetBookingById = ({
  id,
  enabled,
}: {
  id: string
  enabled: boolean
}) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["BOOKING", id],
    async () => {
      const booking = await axiosInstance.get(`api/bookings/${id}`)
      return booking.data
    },
    {
      enabled: enabled,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetBookings = (
  start: any,
  end: any,
  sortQuery: any,
  searchText: any,
  product_ids: any,
  channel_ids: any,
  showOnlyValidBookings: any,
  showByEventDate: any,
  canFetchBookings: any,
  handleChatFetchSuccessCallback: any
) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    ["BOOKING_PAGINATED", start, end],
    ({ pageParam = 1 }) =>
      axiosInstance.post(`api/bookings/pagination`, {
        start,
        end,
        page: pageParam,
        searchText,
        product_ids,
        channel_ids,
        showByEventDate,
      }),
    {
      getNextPageParam: ({ data }: any) => {
        if (data.hasNextPage) {
          return data.nextPage
        }
        return undefined
      },
      enabled: canFetchBookings,
      staleTime: Infinity,
      onSuccess: (res: any) => {
        handleChatFetchSuccessCallback && handleChatFetchSuccessCallback(res)
      },
      select: (data: any) => {
        const pages = data.pages
        const flattenedBookingsFromAllPages = pages
          .flatMap((page: any) => page.data.docs)
          .filter((booking: any) => {
            if (showOnlyValidBookings) {
              return !booking.cancelled
            } else {
              return true
            }
          })

        const accumulatedProfit = flattenedBookingsFromAllPages.reduce(
          (acc: any, booking: any) => acc + booking.total_paid,
          0
        )

        const accumulatedSeatsCount = flattenedBookingsFromAllPages.reduce(
          (acc: any, booking: any) => {
            const { tickets } = booking
            const ticketsArray = Object.entries(tickets)
            const seatsCount = ticketsArray.reduce(
              (acc: any, [key, value]) => acc + value,
              0
            )
            return acc + seatsCount
          },
          0
        )

        return {
          bookings: flattenedBookingsFromAllPages,
          currentPage: pages[pages.length - 1].data.page,
          totalPages: pages[pages.length - 1].data.totalPages,
          totalDocs: pages[pages.length - 1].data.totalDocs,
          selectionProfit: accumulatedProfit,
          selectionSeatsCount: accumulatedSeatsCount,
        }
      },
    }
  )
}

export const useSearchBookings = ({
  searchTerm,
  enabled,
}: {
  searchTerm: string
  enabled: boolean
}) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["SEARCH_BOOKINGS", searchTerm],
    () =>
      axiosInstance.post(`api/bookings/search_booking`, {
        search_term: searchTerm,
      }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  )
}

//----------------------------------------------------------------------------------------

export const useGetVehicleTrackingData = () => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    "VEHICLE_TRACKING_DATA",
    async () => {
      const trackingData = await axiosInstance.get(`api/tracking/all`)
      return trackingData.data
    },
    {
      staleTime: Infinity,
      onError: (error: any) => {
        alert(error.response.data)
      },
    }
  )
}

//----------------------------------------------------------------------------------------

export const useGetTasksByDate = (date: any) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["TASKS_BY_DATE", date],
    async () => {
      const tasks = await axiosInstance.get(
        `api/tasks/get_tasks_by_date/${date}`
      )
      return tasks.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useFetchScheduleData = (
  enabled: any,
  user_id: any,
  skip: any,
  start_date: any,
  end_date: any
) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["SCHEDULE_DATA", user_id, skip, start_date, end_date],
    async () => {
      const scheduleData = await axiosInstance.get(
        `api/tasks/paginated/${user_id}`,
        {
          params: {
            skip: skip,
            start_date: start_date,
            end_date: end_date,
          },
        }
      )
      return scheduleData.data
    },
    {
      //staleTime 5 minutes
      staleTime: 1000 * 60 * 5,
      enabled: enabled,
      onError: (error: any) => {
        alert(error.response.data)
      },
    }
  )
}

//-----------------------------------------------------------------------------------

export const useFetchScheduleByDateRange = (date_range: any, user_ids: any) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["SCHEDULE_DATA_BY_DATE_RANGE", date_range, user_ids],
    async () => {
      const scheduleData = await axiosInstance.post(
        `api/user_day_schedule/get_dates`,
        {
          date_range: date_range,
          user_ids: user_ids,
        }
      )
      return scheduleData.data
    },
    {
      //staleTime 5 minutes
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  )
}

//-----------------------------------------------------------------------------------

export const useSendEmail = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/emails/send_email`, payload)
  )
}

export const useSendGuideEmail = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    (payload: any) =>
      axiosInstance.post(`api/emails/send_guide_email`, payload),
    {
      onSuccess: (data: any) => {
        alert(data.data)
      },
      onError: (error: any) => {
        alert(error.toString())
      },
    }
  )
}

//-----------------------------------------------------------------------------------

export const useAddOrUpdateUserDaySchedule = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    async (payload: any) => {
      const data = await axiosInstance.post(`api/user_day_schedule`, payload)
      return data.data
    },
    {
      onError: (error: any) => {
        alert(error.response.data)
        return
      },
    }
  )
}

//-----------------------------------------------------------------------------------

export const useFetchServiceLogEntries = (vehicle_id: any) => {
  const { axiosInstance } = useAxios()
  return useInfiniteQuery(
    "SERVICE_LOG_ENTRIES",
    ({ pageParam = 1 }) =>
      axiosInstance.get(
        `api/vehicles/service_log_entries/${vehicle_id}/${pageParam}`
      ),
    {
      staleTime: Infinity,
      //select: (data) => data.pages.flatMap((page) => page.data.docs)
    }
  )
}

//-----------------------------------------------------------------------------------

export const useUpdateUserNotificationsPreferences = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ user_id, values: payload }: any) =>
    axiosInstance.patch(
      `api/users/bookings_manager/update_notifications_preferences/${user_id}`,
      payload
    )
  )
}

//-----------------------------------------------------------------------------------

export const useAddNewUser = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) => {
    //console.log("useAddNewUser:", payload);
    return axiosInstance.post(`api/users`, payload)
  })
}

export const useUpdateUser = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: any) => {
    //console.log("useUpdateUser:", payload);
    return axiosInstance.patch(`api/users/update/${id}`, payload)
  })
}

export const useDeleteUser = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) => axiosInstance.delete(`api/users/${id}`))
}

export const useLogoutUserFromMobileDevices = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.post(`api/users/logout_devices/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useAddNewVehicle = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/vehicles`, payload)
  )
}

export const useUpdateVehicle = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: any) =>
    axiosInstance.patch(`api/vehicles/update/${id}`, payload)
  )
}

export const useDeleteVehicle = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.delete(`api/vehicles/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useAddNewBooking = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/bookings`, payload)
  )
}

export const useDuplicateBooking = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ user_id, booking_id }: any) =>
    axiosInstance.post(`api/bookings/duplicate/${user_id}/${booking_id}`)
  )
}

export const useUpdateBooking = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: any) =>
    axiosInstance.put(`api/bookings/with_tour_groups/${id}`, payload)
  )
}

export const useDeleteBooking = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.delete(`api/bookings/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useAddNewServiceLogEntry = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/vehicles/service_log_entry`, payload)
  )
}

export const useUpdateServiceLogEntry = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: any) =>
    axiosInstance.patch(`api/vehicles/service_log_entry/${id}`, payload)
  )
}

export const useDeleteServiceLogEntry = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.delete(`api/vehicles/service_log_entry/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useMarkAllNotificationsAsRead = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.put(`api/notifications/mark_all_as_read/${id}`)
  )
}

export const useMarkNotificationAsRead = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ user_id, id }: any) =>
    axiosInstance.put(`api/notifications/mark_as_read/${user_id}/${id}`)
  )
}

export const useMarkNotificationAsUnread = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ user_id, id }: any) =>
    axiosInstance.put(`api/notifications/mark_as_unread/${user_id}/${id}`)
  )
}

//-----------------------------------------------------------------------------------

// export const storePwaPushSubscription = async (payload) =>
//   AxiosClientSuiteServer.post(`api/pwa_push_subscriptions/subscribe`, payload);

export const useStorePwaPushSubscription = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) => {
    console.log("useStorePwaPushSubscription:", payload)
    return axiosInstance.post(`api/pwa_push_subscriptions/subscribe`, payload)
  })
}

//-----------------------------------------------------------------------------------

export const useAddOrEditMeetingPoint = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/meeting_points`, payload)
  )
}

export const useDeleteMeetingPoint = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.delete(`api/meeting_points/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useAddTourGroupFromGuidePlan = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(
      `api/tour_groups/add_from_tour_guide_planner_form`,
      payload
    )
  )
}

export const useGetUserDaySchedulesThatIncludesTourGroup = (
  tour_group_id: string
) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP", tour_group_id],
    async () => {
      const userDaySchedules = await axiosInstance.get(
        `api/user_day_schedule/tour_group/${tour_group_id}`
      )
      return userDaySchedules.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}

export const useAddTourGroupToUserSchedule = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_id,
      role_id,
      assignee_id,
    }: {
      tour_group_id: string
      role_id: string
      assignee_id: string
    }) =>
      axiosInstance.post(`api/user_day_schedule/add_tour_group`, {
        tour_group_id,
        role_id,
        assignee_id,
      })
  )
}

export const useRemoveTourGroupFromUserSchedule = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_id,
      schedule_id,
      assignee_id,
    }: {
      tour_group_id: string
      schedule_id: string
      assignee_id: string
    }) =>
      axiosInstance.post(`api/user_day_schedule/remove_tour_group`, {
        tour_group_id,
        schedule_id,
        assignee_id,
      })
  )
}

export const useAddOrDeleteVehicleIdToTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_id,
      vehicle_id,
    }: {
      tour_group_id: string
      vehicle_id: [string]
    }) =>
      axiosInstance.patch(
        `api/tour_groups/add_or_delete_vehicle/${tour_group_id}`,
        { vehicle_id: vehicle_id }
      )
  )
}

export const useAddOrDeleteVehiclePlatformEntryToTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_id,
      vehicle_platform_entry,
    }: {
      tour_group_id: string
      vehicle_platform_entry: string
    }) =>
      axiosInstance.patch(
        `api/tour_groups/add_vehicle_platform_entry/${tour_group_id}`,
        { vehicle_platform_entry: vehicle_platform_entry }
      )
  )
}

export const useAddOrUpdateNoteInTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, notes }: { id: string; notes: [Object] }) =>
    axiosInstance.patch(`api/tour_groups/add_note/${id}`, { notes })
  )
}

export const useAddConfirmationToTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({ id, confirmation }: { id: string; confirmation: string }) =>
      axiosInstance.patch(`api/tour_groups/add_confirmation/${id}`, {
        confirmation,
      })
  )
}

export const useAddGuideDetailsToTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, details }: { id: string; details: string }) =>
    axiosInstance.patch(`api/tour_groups/add_guide_details/${id}`, {
      details,
    })
  )
}

export const useAddGuideToTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({ id, guide_id }: { id: string; guide_id: string }) =>
      axiosInstance.patch(`api/user_day_schedule/add_guide/${id}`, { guide_id })
  )
}

export const useReplaceGuideFromTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      id,
      guide_id,
      uds_id,
    }: {
      id: string
      guide_id: string
      uds_id: string
    }) =>
      axiosInstance.patch(`api/user_day_schedule/replace_guide/${id}`, {
        uds_id,
        guide_id,
      })
  )
}

export const useRemoveGuideFromTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, uds_id }: { id: string; uds_id: string }) =>
    axiosInstance.patch(`api/user_day_schedule/remove_guide/${id}`, { uds_id })
  )
}

export const useAddTourGuidesInTourGroupGuidesAskedArray = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({ id, guide_ids }: { id: string; guide_ids: string }) =>
      axiosInstance.patch(`api/tour_groups/add_guides_to_guides_asked/${id}`, {
        guide_ids,
      })
  )
}

export const useAddTourGuidesInMultipleTourGroupsGuidesAskedArray = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_ids,
      guide_ids,
    }: {
      tour_group_ids: string[]
      guide_ids: string[]
    }) =>
      axiosInstance.patch(
        `api/tour_groups/add_guides_to_guides_asked_multiple_tour_groups`,
        {
          tour_group_ids,
          guide_ids,
        }
      )
  )
}

export const useHideTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.patch(`api/tour_groups/hide/${id}`)
  )
}

export const useSwapTourGroupsResources = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((ids: any) =>
    axiosInstance.patch(`api/tour_groups/swap_resources/${ids[0]}/${ids[1]}`)
  )
}


export const useRemovePickupTimesFromAllBookingsOfTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((tour_group_id: string) =>
    axiosInstance.patch(`api/tour_groups/remove_pickup_times`, {
      tour_group_id,
    })
  )
}

export const useAddPickupTimeToAllBookingsOfTourGroup = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    ({
      tour_group_id,
      pickup_time,
    }: {
      tour_group_id: string
      pickup_time: string
    }) =>
      axiosInstance.patch(`api/tour_groups/add_pickup_time`, {
        tour_group_id,
        pickup_time,
      })
  )
} 






//-----------------------------------------------------------------------------------

export const useSendOlpEmail = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(
    (payload: any) => axiosInstance.post(`api/emails/send_olp_emails`, payload),
    {
      onSuccess: (data: any) => {
        alert(data.data)
      },
      onError: (error: any) => {
        alert(error.toString())
      },
    }
  )
}

//-----------------------------------------------------------------------------------

export const useGetAnnouncements = () => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    "ANNOUNCEMENTS",
    ({ pageParam = 1 }) =>
      axiosInstance.get(`api/announcements/paginated/${pageParam}`),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },

      staleTime: Infinity,
    }
  )
}

export const useAddNewAnnouncement = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/announcements`, payload)
  )
}

export const useUpdateAnnouncement = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: any) =>
    axiosInstance.patch(`api/announcements/${id}`, payload)
  )
}

// export const useUpdateUser = () => {
//   const { axiosInstance } = useAxios();
//   return useCustomMutation(({ id, payload }: any) => {
//     //console.log("useUpdateUser:", payload);
//     return axiosInstance.patch(`api/users/update/${id}`, payload);
//   });
// };

export const useDeleteAnnouncement = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: any) =>
    axiosInstance.delete(`api/announcements/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useGetBookingPortalSessionsByRef = (bookingRef: string) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(["BOOKING_PORTAL_SESSIONS_BY_REF", bookingRef], () =>
    axiosInstance.get(`api/booking_portal_sessions/booking/${bookingRef}`)
  )
}

export const useGetOnlineSessions = () => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    "ONLINE_SESSIONS",
    () => axiosInstance.get(`api/booking_portal_sessions/bookings/online`),
    {
      //staleTime: Infinity, //is refetched with socket emits from header
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60 * 5, //refetch every 5 minutes
    }
  )
}

export const useGetOfflineSessionsPaginated = (payload: any) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    "SESSIONS_PAGINATED",
    ({ pageParam = 1 }) =>
      axiosInstance.post(
        `api/booking_portal_sessions/bookings/offline_paginated/${pageParam}`,
        payload
      ),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },

      staleTime: Infinity,
    }
  )
}

//-----------------------------------------------------------------------------------

export const useAddNewProduct = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/products`, payload)
  )
}

export const useCloneProduct = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.post(`api/products/clone/${id}`)
  )
}

export const useUpdateProduct = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: { id: string; payload: any }) =>
    axiosInstance.put(`api/products/${id}`, payload)
  )
}

export const useDeleteProduct = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.delete(`api/products/${id}`)
  )
}

export const useDeleteProductPicture = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, url }: { id: string; url: string }) =>
    axiosInstance.post(`api/products/${id}/delete_picture`, {
      url,
    })
  )
}

export const useSortProducts = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((ids: any) =>
    axiosInstance.patch(`api/products/sort`, {
      ids,
    })
  )
}

export const useGetBokunProduct = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.get(`api/products/bokun/${id}`)
  )
}

export const useGetAvailabilityOfProductByDateAndTime = ({
  productId,
  startTimeId,
  date,
  time,
}: {
  productId: string
  startTimeId: string
  date: string
  time: string
}) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    [
      "AVAILABILITY_OF_PRODUCT_BY_DATE_AND_TIME",
      productId,
      startTimeId,
      date,
      time,
    ],
    async () => {
      const availability = await axiosInstance.get(
        `api/products/availability/${productId}/${startTimeId}/${date}/${time}`
      )
      return availability.data
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  )
}

//-----------------------------------------------------------------------------------

export const useAddMessageDraft = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/message_drafts`, payload)
  )
}

export const useUpdateMessageDraft = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: { id: string; payload: any }) =>
    axiosInstance.put(`api/message_drafts/${id}`, payload)
  )
}

export const useDeleteMessageDraft = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.delete(`api/message_drafts/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useGetNotes = (author_id: string) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    "NOTES",
    ({ pageParam = 1 }) =>
      axiosInstance.get(`api/notes/${author_id}/${pageParam}`),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },

      staleTime: Infinity,
    }
  )
}

export const useAddOrUpdateNote = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation(({ id, payload }: { id: string; payload: any }) =>
    axiosInstance.post(`api/notes/${id}`, payload)
  )
}

export const useDeleteNote = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.delete(`api/notes/${id}`)
  )
}

//-----------------------------------------------------------------------------------

export const useGetCalendarNotes = ({
  date,
  user_id,
}: {
  date: string
  user_id: string
}) => {
  const { axiosInstance } = useAxios()
  return useCustomInfiniteQuery(
    ["CALENDAR_NOTES", date],
    ({ pageParam = 1 }) =>
      axiosInstance.get(
        `api/calendar_notes/paginated/${user_id}/${date}/${pageParam}`
      ),
    {
      getNextPageParam: (lastPage: any, allPages: any) => {
        if (lastPage.data.hasNextPage) {
          return lastPage.data.nextPage
        }
        return undefined
      },

      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  )
}

export const useGetAllCalendarNotes = ({
  date,
  user_id,
}: {
  date: string
  user_id: string
}) => {
  const { axiosInstance } = useAxios()
  return useCustomQuery(
    ["ALL_CALENDAR_NOTES", date],
    () => axiosInstance.get(`api/calendar_notes/all/${date}/${user_id}`),
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  )
}

export const useAddNewCalendarNote = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.post(`api/calendar_notes`, payload)
  )
}

export const useUpdateCalendarNote = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((payload: any) =>
    axiosInstance.put(`api/calendar_notes/update`, payload)
  )
}

export const useDeleteCalendarNote = () => {
  const { axiosInstance } = useAxios()
  return useCustomMutation((id: string) =>
    axiosInstance.delete(`api/calendar_notes/${id}`)
  )
}

