import { useState } from 'react';
import { ContentItem, ContentItemContent, ContentItemTitle } from './styled';
import { useFetchEntities } from '../../../../reactQueryHooks';
import AddVehicleModal from './AddVehicleModal';
import { useAxios } from '../../../../axiosProvider';
import Tooltip from "@mui/material/Tooltip";

//-------------------------------------------

//-------------------------------------------

const SortableTableInfoVehicle = ({ tourGroup, refetch }) => {
  const [isAddVehicleModalShown, setIsAddVehicleModalShown] = useState(false);
  const { data: entities } = useFetchEntities();

    const product = entities?.data?.products?.find(
      (product) => product._id === tourGroup?.product_id
    );

    const product_option = product?.options?.find(
      (option) => option.bokun_code === tourGroup?.option_id
    );

    const hasVehicle =
      tourGroup?.vehicle_id !== null && tourGroup?.vehicle_id !== undefined;

    const vehicle = entities.data.vehicles.find(
      (v) => v._id === tourGroup?.vehicle_id
    );
    const hasPlatform =
      tourGroup?.vehicle_platform_entry === tourGroup?.vehicle_id;
    const { user } = useAxios();
    const userIsAdmin = user.isAdmin;
    const userIsPermittedToAssignVehicle =
      user.permissions.assignVehicle || userIsAdmin;
    const platformRequired =
      vehicle?.platform_entry_required && product_option.requires_platformEntry;
  const platformOk =
    hasVehicle && vehicle?.platform_entry_required && hasPlatform;
  const shouldAlertPlatform = hasVehicle && platformRequired && !hasPlatform;

  return (
    <ContentItem
      title="Add group vehicle"
      //a fade in with a little left to right movement
      initial={{ opacity: 0, x: -5 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -5 }}
      transition={{ duration: 0.25, delay: 0.35 }}
      onClick={() => {
        if (userIsPermittedToAssignVehicle) {
          setIsAddVehicleModalShown(true);
        } else {
          alert("You do not have permission to assign vehicle");
        }
      }}
      style={{
        border: shouldAlertPlatform ? "2px solid indianred" : "none",
      }}
    >
      <ContentItemTitle>Vehicle</ContentItemTitle>
      <ContentItemContent>
        <i className="fa-solid fa-bus"></i>
        {hasVehicle && <span>{vehicle.plate}</span>}
        {!hasVehicle && (
          <span
            style={{
              color: "indianred",
              fontWeight: "bold",
            }}
          >
            Not assigned
          </span>
        )}
        {platformRequired && platformOk && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "5px",
            }}
          >
            <span
              style={{
                color: "darkgreen",
              }}
            >
              PLATFORM
            </span>
            <i
              style={{
                color: "darkgreen",
                fontSize: "12px",
              }}
              className="fa-solid fa-check"
            ></i>
          </span>
        )}
        {platformRequired && !platformOk && (
          <Tooltip title="NEEDS PLATFORM ENTRY !" placement="top">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <span
                style={{
                  color: "indianred",
                }}
              >
                PLATFORM
              </span>
              <i
                style={{
                  color: "indianred",
                  fontSize: "11px",
                }}
                className="fa-solid fa-triangle-exclamation"
              ></i>
            </span>
          </Tooltip>
        )}
      </ContentItemContent>
      {isAddVehicleModalShown && (
        <AddVehicleModal
          close={() => setIsAddVehicleModalShown(false)}
          tourGroup={tourGroup}
          refetch={refetch}
          shouldShowButton={shouldAlertPlatform}
        />
      )}
    </ContentItem>
  );
};

export default SortableTableInfoVehicle;