import styled from "styled-components";
import Header from "../components/header/Header";
import AnnouncementsContent from "../components/bookings_manager/announcements/content/AnnouncementsContent";
import { useNavigate } from "react-router-dom";

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const Announcements = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header
        text={"Announcements"}
        redirectFn={() => {
          navigate("/home");
        }}
      />
      <ContentContainer>
        <AnnouncementsContent />
      </ContentContainer>
    </Container>
  );
};

export default Announcements;
