import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
//import mapboxgl from "mapbox-gl";

import BarLoader from "react-spinners/BarLoader";
import MovingMarkers from "./MovingMarkers";
import MeetingPoints from "./MeetingPoints";
import toast from "react-hot-toast"

import "mapbox-gl/dist/mapbox-gl.css"

//imported in index.html
// @ts-ignore
mapboxgl.accessToken =
  "pk.eyJ1Ijoibm9zcGlwaSIsImEiOiJjbGVoOWc2MWMwYjVzM29xZjJkNjlnZzU3In0.ygeBpLtHj3dWNMii9EL1qw"

//----------------------------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
`

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 5px;
`

const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(227, 238, 241, 0.8);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

//------------------------------------------------------------------------

const Map = () => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<mapboxgl.Map>()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (mapContainer.current) {
      // @ts-ignore
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [23.72656913921858, 37.97340314524558],
        zoom: 10,
      })
      setMap(newMap)

      newMap.on("load", () => {
        setIsLoaded(true)
      })

      // newMap.on("click", (e) => {
      //   const coordinates = e.lngLat
      //   const { lng, lat } = coordinates
      //   const url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&fov=80&heading=70&pitch=0&t=p`
      //   window.open(url, "_blank")
      // })

      newMap.on("contextmenu", (e) => {
        const coordinates = e.lngLat
        const { lng, lat } = coordinates
        e.preventDefault() // Prevent the default context menu from appearing
        const googleMapsUrl = `https://www.google.com/maps/place/${lat},${lng}`
        //@ts-ignore
        const popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<div style="display: flex; gap: 3px; flex-direction: column; padding: 10px;">
              <span>Coordinates: ${lng.toFixed(5)}, ${lat.toFixed(5)}</span>
              <button style="border-radius:5px; background:rgb(216 216 216); padding: 5px;" onclick="window.open('${googleMapsUrl}', '_blank')">Open in Google Maps</button>
              <button style="border-radius:5px; background:rgb(216 216 216); padding: 5px;" id="copyButton">Copy Google Maps URL</button>
            </div>
            `
          )
          .addTo(newMap)

        setTimeout(() => {
          popup.remove()
        }, 5000)

        // Add event listener to the copy button after the popup is added to the DOM
        setTimeout(() => {
          const copyButton = document.getElementById("copyButton")
          if (copyButton) {
            copyButton.addEventListener("click", () => {
              navigator.clipboard.writeText(googleMapsUrl).then(
                () => {
                  toast.success("URL copied to clipboard!")
                },
                () => {
                  toast.error("Failed to copy URL to clipboard!")
                }
              )
            })
          }
        }, 0)
      })
    }
  }, [])

  return (
    <Container>
      {map && <MovingMarkers map={map} />}
      {map && <MeetingPoints map={map} />}
      {!isLoaded && (
        <LoaderOverlay>
          <BarLoader color="#606060" loading={true} />
        </LoaderOverlay>
      )}
      <MapContainer ref={mapContainer} />
    </Container>
  )
}

export default Map;
