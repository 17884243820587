import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { storage } from "../../../../../firebase";
import { ref, deleteObject } from "firebase/storage";
import { Formik, useFormikContext } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDeleteProductPicture } from "../../../../../reactQueryHooks";

//-----------------------------------------------------------

const ProductImageCard = ({
  file,
  productId,
  setFolderFilesUrls,
}: {
  file: any;
  productId: string;
  setFolderFilesUrls: (value: any) => void;
}) => {
  const productFormFormikContext = useFormikContext() as any;
  const { values: productFormValues, setFieldValue: setProductFormFieldValue } =
    productFormFormikContext;
  const includedInGallery = productFormValues?.product_pictures?.find(
    (picture: any) => picture.url === file.url
  );
  const isCoverPhoto =
    productFormValues?.product_pictures?.[0]?.url === file.url;
  const { mutateAsync: deleteProductPicture } = useDeleteProductPicture();

  const handleDeleteFile = async (filename: string, url: string) => {
    try {
      // Get the reference to the file in Firebase Storage
      const storageRef = ref(
        storage,
        `/products/${productId}/images/${filename}`
      );

      // Delete the file
      await deleteObject(storageRef);

      // Remove the file from the folderFilesUrls state
      setFolderFilesUrls((prevUrls: any) =>
        prevUrls.filter((file: any) => file.url !== url)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Error deleting file");
    }
  };

  //-----------------------------------------------------------

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        alt: includedInGallery?.alt || "",
        description: includedInGallery?.description || "",
        caption: includedInGallery?.caption || "",
      }}
      validationSchema={Yup.object({
        alt: Yup.string().required("Alt text is required"),
        description: Yup.string().required("Description is required"),
        caption: Yup.string().required("Caption is required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        console.log(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "7px",
              boxShadow: "0px 0px 4px 0px rgba(146 146 146 / 0.75)",
              backgroundColor: "rgb(250 250 250)",
              //boxShadow: "none",
              borderRadius: "8px",
              padding: "13px",
              //minWidth: "300px",
              //maxWidth: "400px",
            }}
          >
            {/* Left side - Checkbox */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: includedInGallery
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {includedInGallery && (
                <FormControlLabel
                  control={
                    isCoverPhoto ? (
                      <span
                        style={{
                          marginLeft: "12px",
                        }}
                      >
                        Cover photo{" "}
                        <i
                          style={{
                            color: "darkgreen",
                          }}
                          className="fas fa-check-circle"
                        ></i>
                      </span>
                    ) : (
                      <Checkbox
                        sx={{
                          color: "indigo",
                          marginLeft: "10px",
                          padding: "0px",
                        }}
                      />
                    )
                  }
                  label={isCoverPhoto ? "" : "Set as cover photo"}
                  checked={isCoverPhoto}
                  onChange={(event: any) => {
                    if (event.target.checked) {
                      //remove from array and add to the beginning
                      const newProductPictures =
                        productFormValues.product_pictures.filter(
                          (picture: any) => picture.url !== file.url
                        );
                      newProductPictures.unshift({
                        url: file.url,
                        alt: values.alt,
                        description: values.description,
                        caption: values.caption,
                      });
                      setProductFormFieldValue(
                        "product_pictures",
                        newProductPictures
                      );
                      //IN folderFilesUrls as well
                      setFolderFilesUrls((prevUrls: any) => {
                        const newUrls = prevUrls.filter(
                          (picture: any) => picture.url !== file.url
                        );
                        newUrls.unshift({
                          url: file.url,
                          filename: file.filename,
                        });
                        return newUrls;
                      });
                    } else {
                      //put it second
                      const newProductPictures =
                        productFormValues.product_pictures.filter(
                          (picture: any) => picture.url !== file.url
                        );
                      newProductPictures.splice(1, 0, {
                        url: file.url,
                        alt: values.alt,
                        description: values.description,
                        caption: values.caption,
                      });
                      setProductFormFieldValue(
                        "product_pictures",
                        newProductPictures
                      );
                      //IN folderFilesUrls as well
                      setFolderFilesUrls((prevUrls: any) => {
                        const newUrls = prevUrls.filter(
                          (picture: any) => picture.url !== file.url
                        );
                        newUrls.splice(1, 0, {
                          url: file.url,
                          filename: file.filename,
                        });
                        return newUrls;
                      });
                    }
                  }}
                />
              )}
              <Button
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this image?"
                    )
                  ) {
                    try {
                      await handleDeleteFile(file.filename, file.url);
                      setProductFormFieldValue(
                        "product_pictures",
                        productFormValues.product_pictures.filter(
                          (picture: any) => picture.url !== file.url
                        )
                      );
                      const response = await deleteProductPicture({
                        id: productId,
                        url: file.url,
                      } as any);
                      //@ts-ignore
                      toast.success(response?.data);
                    } catch (error) {
                      alert(error?.toString());
                    }
                  }
                }}
                sx={{
                  background: "indianred",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgb(129 76 76)",
                  },
                }}
              >
                DELETE
              </Button>
            </div>
            {/* Middle - Picture */}
            <CardMedia
              component="img"
              alt="Your Alt Text"
              height="250"
              image={file.url}
              sx={{
                objectFit: "contain", //default cover
                alignSelf: "center",
                borderRadius: "5px",
                width: "100%",
              }}
            />

            {/* Right side - Input fields */}

            <TextField
              fullWidth
              id="alt"
              label="Alt Text"
              color="success"
              variant="outlined"
              margin="dense"
              multiline
              sx={{
                backgroundColor: "white",
                width: "100%",
              }}
              error={errors.alt ? true : false}
              value={values.alt}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="description"
              label="Description"
              color="success"
              variant="outlined"
              margin="dense"
              multiline
              sx={{
                backgroundColor: "white",
              }}
              error={errors.description ? true : false}
              value={values.description}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="caption"
              label="Caption"
              color="success"
              variant="outlined"
              margin="dense"
              multiline
              sx={{
                backgroundColor: "white",
              }}
              error={errors.caption ? true : false}
              value={values.caption}
              onChange={handleChange}
            />
            {includedInGallery ? (
              <span
                style={{
                  color: "green",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Image is included in the gallery
                <i
                  className="fas fa-check-circle"
                  style={{ marginLeft: "5px" }}
                ></i>
              </span>
            ) : (
              <span
                style={{
                  color: "indianred",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                This image is not included in the gallery
                <i
                  className="fas fa-times-circle"
                  style={{ marginLeft: "5px" }}
                ></i>
              </span>
            )}
            <Button
              sx={{
                background: includedInGallery ? "indianred" : "darkgreen",
                color: "white",
                "&:hover": {
                  backgroundColor: includedInGallery
                    ? "rgb(129 76 76)"
                    : "rgb(76 129 76)",
                },
              }}
              onClick={() => {
                if (Object.keys(errors).length > 0) {
                  validateForm();
                  const errorsString = Object.values(errors).join("\n");
                  alert(errorsString);
                } else {
                  if (includedInGallery) {
                    setProductFormFieldValue(
                      "product_pictures",
                      productFormValues.product_pictures.filter(
                        (picture: any) => picture.url !== file.url
                      )
                    );
                  } else {
                    setProductFormFieldValue("product_pictures", [
                      ...productFormValues.product_pictures,
                      {
                        url: file.url,
                        alt: values.alt,
                        description: values.description,
                        caption: values.caption,
                      },
                    ]);
                  }
                }
              }}
            >
              {includedInGallery ? "Remove from gallery" : "Add to gallery"}
            </Button>
          </Box>
        );
      }}
    </Formik>
  );
};

export default ProductImageCard;
